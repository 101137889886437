import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhaseEntitiesViewComponent } from './views/phase-entities-view/phase-entities-view.component';
import { PhaseEntityViewComponent } from './views/phase-entity-view/phase-entity-view.component';
import { RouterModule, Routes } from '@angular/router';
import { CbUtilityWidgetsModule } from 'src/app/widgets/cb-utility-widgets/cb-utility-widgets.module';
import { CbFormWidgetsModule } from 'src/app/widgets/cb-form-widgets/cb-form-widgets.module';
import { TranslateModule } from '@ngx-translate/core';
import { CbIconModule } from 'src/app/widgets/cb-icon/cb-icon.module';
import { TableModule } from 'primeng/table';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CbPopoverModule } from 'src/app/widgets/cb-popover/cb-popover.module';
import { CommonDirectivesModule } from 'src/app/directives/common-directives/common-directives.module';
import { TooltipModule } from 'primeng/tooltip';
import { CloseLifecycleDialogComponent } from './dialogs/close-lifecycle-dialog/close-lifecycle-dialog.component';
import { NewActionDialogComponent } from './dialogs/new-action-dialog/new-action-dialog.component';
import { NewLifecycleDialogComponent } from './dialogs/new-lifecycle-dialog/new-lifecycle-dialog.component';
import { EditEntityPropertiesDialogComponent } from './dialogs/edit-entity-properties-dialog/edit-entity-properties-dialog.component';
import { EditEntityActionsDialogComponent } from './dialogs/edit-entity-actions-dialog/edit-entity-actions-dialog.component';
import { CreateEditEntityDialogComponent } from './dialogs/create-edit-entity-dialog/create-edit-entity-dialog.component';
import { CbDialogModule } from 'src/app/widgets/cb-dialog/cb-dialog.module';
import { ActionFormWidgetComponent } from './widgets/action-form-widget/action-form-widget.component';
import { FormsModule } from '@angular/forms';
import { CommonPipesModule } from 'src/app/pipes/common-pipes/common-pipes.module';
import { FieldsCustomizationWidgetComponent } from './widgets/fields-customization-widget/fields-customization-widget.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { QRCodeModule } from 'angularx-qrcode';
import { QrcodeDialogComponent } from './dialogs/qrcode-dialog/qrcode-dialog.component';
import { PhaseEntityLifecyclesViewComponent } from './views/phase-entity-lifecycles-view/phase-entity-lifecycles-view.component';
import { CancelLifecycleDialogComponent } from './dialogs/cancel-lifecycle-dialog/cancel-lifecycle-dialog.component';

export enum PhaseType {
  vineyard = "vineyards",
  winemaking = "winemakings",
  lot = "lots",
}

export const PHASES_LIST = [
  { type: PhaseType.vineyard, name: "PHASES.TYPES.VINEYARD", icon: 'seeding' },
  { type: PhaseType.winemaking, name: "PHASES.TYPES.WINEMAKING", icon: 'bubbles' },
  { type: PhaseType.lot, name: "PHASES.TYPES.LOT", icon: 'wine-glass' },
];

const routes: Routes = [
  {
    path: 'vineyards',
    data: {
      phase: PhaseType.vineyard
    },
    children: [
      {
        path: '',
        component: PhaseEntitiesViewComponent,
      },
      {
        path: ':id',
        component: PhaseEntityViewComponent,
      },
      {
        path: ':id/lifecycles',
        component: PhaseEntityLifecyclesViewComponent,
      }
    ]
  },
  {
    path: 'winemakings',
    data: {
      phase: PhaseType.winemaking
    },
    children: [
      {
        path: '',
        component: PhaseEntitiesViewComponent,
      },
      {
        path: ':id',
        component: PhaseEntityViewComponent,
      },
      {
        path: ':id/lifecycles',
        component: PhaseEntityLifecyclesViewComponent,
      }
    ]
  },
  {
    path: 'lots',
    data: {
      phase: PhaseType.lot
    },
    children: [
      {
        path: '',
        component: PhaseEntitiesViewComponent,
      },
      {
        path: ':id',
        component: PhaseEntityViewComponent,
      },
      {
        path: ':id/lifecycles',
        component: PhaseEntityLifecyclesViewComponent,
      }
    ]
  },
];

@NgModule({
  declarations: [
    PhaseEntitiesViewComponent,
    PhaseEntityViewComponent,
    CloseLifecycleDialogComponent,
    NewActionDialogComponent,
    NewLifecycleDialogComponent,
    EditEntityPropertiesDialogComponent,
    EditEntityActionsDialogComponent,
    CreateEditEntityDialogComponent,
    ActionFormWidgetComponent,
    FieldsCustomizationWidgetComponent,
    QrcodeDialogComponent,
    PhaseEntityLifecyclesViewComponent,
    CancelLifecycleDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CbUtilityWidgetsModule,
    CbFormWidgetsModule,
    FormsModule,
    TranslateModule,
    CbIconModule,
    TableModule,
    OverlayPanelModule,
    CbPopoverModule,
    CommonDirectivesModule,
    TooltipModule,
    CbDialogModule,
    CommonPipesModule,
    DragDropModule,
    QRCodeModule
  ]
})
export class PhasesModule { }
