<div class="cb-dialog-form-field">
  <ng-container [ngSwitch]="field.type">
    <!-- TEXT -->
    <ng-container *ngSwitchCase="'text'">
      <cb-input [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value" type="text"
        [label]="(hideLabel)? '' : label" [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''">
      </cb-input>
    </ng-container>

    <!-- NUMBER -->
    <ng-container *ngSwitchCase="'number'">
      <cb-input [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value"
        type="number" [label]="(hideLabel)? '' : label"
        [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''">
      </cb-input>
    </ng-container>

    <!-- TEXTAREA -->
    <ng-container *ngSwitchCase="'textarea'">
      <cb-textarea [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value"
        [rows]="textareaRows" [label]="(hideLabel)? '' : label"
        [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''">
      </cb-textarea>
    </ng-container>

    <!-- SELECT -->
    <ng-container *ngSwitchCase="'enum'">
      <ng-container *ngIf="options">
        <cb-select [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value"
          [label]="(hideLabel)? '' : label" [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''"
          [options]="options" appendTo="body">
        </cb-select>
      </ng-container>
    </ng-container>

    <!-- MULTI SELECT -->
    <ng-container *ngSwitchCase="'multioptions'">
      <ng-container *ngIf="options">
        <cb-multi-select [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value"
          [label]="(hideLabel)? '' : label" [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''"
          [options]="options" appendTo="body">
        </cb-multi-select>
      </ng-container>
    </ng-container>

    <!-- SLIDER -->
    <ng-container *ngSwitchCase="'slider'">
      <cb-slider [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value"
        [label]="(hideLabel)? '' : label" [max]="sliderMax" [min]="sliderMin" [step]="sliderStep"
        [stepLabels]="sliderStepLabels">
      </cb-slider>
    </ng-container>

    <!-- DATE -->
    <ng-container *ngSwitchCase="'date'">
      <cb-date-input [error]="error" (change)="change()" [(ngModel)]="value" [label]="(hideLabel)? '' : label"
        [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''" appendTo="body">
      </cb-date-input>
    </ng-container>

    <!-- TIME -->
    <ng-container *ngSwitchCase="'time'">
      <cb-time-input [error]="error" (change)="change()" [(ngModel)]="value" [label]="(hideLabel)? '' : label"
        [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''"></cb-time-input>
    </ng-container>

    <!-- CHECKBOX -->
    <ng-container *ngSwitchCase="'checkbox'">
      <cb-checkbox [error]="error" (change)="change()" [(ngModel)]="value">
        {{(field.label)? (field.label | translate) : ''}}</cb-checkbox>
    </ng-container>

    <!-- BOOLEAN -->
    <ng-container *ngSwitchCase="'boolean'">
      <cb-checkbox [error]="error" (change)="change()" [(ngModel)]="value">
        {{(field.label)? (field.label | translate) : ''}}</cb-checkbox>
    </ng-container>

    <!-- PASSWORD -->
    <ng-container *ngSwitchCase="'password'">
      <cb-input [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value"
        type="password" [label]="(hideLabel)? '' : label"
        [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''">
      </cb-input>
    </ng-container>

    <!-- STRINGLIST -->
    <ng-container *ngSwitchCase="'stringlist'">
      <cb-chips-input [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value"
        [label]="(hideLabel)? '' : label" [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''">
      </cb-chips-input>
    </ng-container>

    <!-- LIST -->
    <ng-container *ngSwitchCase="'list'">
      <table class="cb-form-table">
        <ng-container *ngIf="listChildren?.headers">
          <thead>
            <ng-container *ngFor="let header of listChildren!.headers">
              <th>{{header | translate}}</th>
            </ng-container>
            <th></th>
          </thead>
        </ng-container>
        <ng-container *ngIf="listChildren?.fields">
          <tbody>
            <ng-container *ngIf="this.value">
              <ng-container *ngFor="let val of this.value; let idx=index">
                <tr>
                  <ng-container *ngFor="let subfield of listChildren?.fields; let colIdx=index">
                    <td>
                      <cb-form-field [field]="subfield" [hideLabel]="true" [(ngModel)]="val[subfield.field]"
                        (change)="change()">
                      </cb-form-field>
                    </td>
                  </ng-container>
                  <td>
                    <div class="cb-form-list-remove" [class.disabled]="!this.canRemoveRow(idx)"
                      (click)="removeRow(idx)">
                      <ion-icon name="close-circle"></ion-icon>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </ng-container>
          </tbody>
        </ng-container>
      </table>
      <ng-container *ngIf="canAddRows">
        <div class="cb-form-add-row-container" (click)="addNewListRow()">
          <div class="cb-form-add-circle">
            <ion-icon name="add"></ion-icon>
          </div>
        </div>
      </ng-container>
    </ng-container>

    <!-- DEFAULT -->
    <ng-container *ngSwitchDefault>
      <cb-input [error]="error" [disabled]="field.disabled ?? false" (change)="change()" [(ngModel)]="value" type="text"
        [label]="(hideLabel)? '' : label" [placeholder]="(field.placeholder)? (field.placeholder | translate) : ''">
      </cb-input>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="error && errorMessage">
    <p class="cb-dialog-form-field-error">{{ errorMessage | translate }}</p>
  </ng-container>
</div>