<div class="cb-dialog-container" [style.width]="width">
  <div class="cb-dialog-header">
    <ng-container *ngIf="title; else CustomTitleTemplate">
      <h4 class="cb-dialog-title">{{title}}</h4>
    </ng-container>
    <ng-template #CustomTitleTemplate>
      <div class="cb-dialog-title-container">
        <ng-content select="[Title]"></ng-content>
      </div>
    </ng-template>
    <ion-icon class="cb-dialog-close-icon" name="close" (click)="closeDialog()"></ion-icon>
  </div>
  <div class="cb-dialog-content">
    <ng-content select="[Content]"></ng-content>
  </div>
  <div class="cb-dialog-buttons">
    <div class="cb-dialog-left-buttons">
      <cb-button *ngIf="closeButton" (click)="closeDialog()">{{"COMMON.CLOSE" | translate}}</cb-button>
      <ng-content select="[LeftButtons]"></ng-content>
    </div>
    <div class="cb-dialog-right-buttons">
      <ng-content select="[RightButtons]"></ng-content>
    </div>
  </div>
</div>
