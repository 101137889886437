<div class="cb-form-container">
    <cb-date-input [placeholder]="'ENTITY.ACTION_FIELDS.PERIOD_PLACEHOLDER' | translate"
        [label]="'ENTITY.ACTION_FIELDS.PERIOD' | translate" [isPeriod]="true" [disabled]="disabled"
        [(ngModel)]="values['period']" appendTo="body"></cb-date-input>
        
    <ng-container *ngFor="let field of fields">
        <ng-container *ngIf="field.isActive">
            <ng-container [ngSwitch]="field.type">
                <ng-container *ngSwitchCase="fieldTypes.select">
                    <cb-select [placeholder]="field.label" [label]="field.label" [(ngModel)]="values[field.name]"
                        [disabled]="disabled" [options]="getFieldOptions(field)" appendTo="body"></cb-select>
                </ng-container>

                <ng-container *ngSwitchDefault="">
                    <cb-input [placeholder]="field.label" [label]="field.label" [disabled]="disabled"
                        [(ngModel)]="values[field.name]"></cb-input>
                </ng-container>
            </ng-container>
        </ng-container>
    </ng-container>

    <div class="cb-form-attachments-container">
        <label>{{"ENTITY.ACTION_FIELDS.ATTACHMENTS" | translate}}</label>
        <cb-button type="dashed" icon="add-circle" (click)="uploadFile()"
            [disabled]="disabled">{{"ENTITY.ACTION_FIELDS.ADD_ATTACHMENT" |
            translate}}</cb-button>
        <ul class="cb-attachments-list">
            <ng-container *ngFor="let att of values['attachments']">
                <li>
                    <div class="cb-attachment-icon">
                        <cb-icon [name]="getIconFromAttachmentUrl(att.extension)"></cb-icon>
                    </div>
                    <p>{{att.name}}</p>
                    <cb-icon name="download" (click)="downloadDocument(att.url)"></cb-icon>
                    <cb-icon class="cb-delete-attachment" (click)="deleteDocument(att)" name="trash"></cb-icon>
                </li>
            </ng-container>
        </ul>
    </div>
</div>