import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UploaderService } from 'src/app/services/uploader.service';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { CbCustomField, CbCustomFieldType } from '../fields-customization-widget/fields-customization-widget.component';
import * as uuid from 'uuid';

@Component({
  selector: 'action-form-widget',
  templateUrl: './action-form-widget.component.html',
  styleUrls: ['./action-form-widget.component.scss']
})
export class ActionFormWidgetComponent implements OnInit {

  public readonly fieldTypes: typeof CbCustomFieldType = CbCustomFieldType;

  @Input() public fields: CbCustomField[] = [];
  @Input() public disabled: boolean = false;

  public values: { [key: string]: any } = {};
  public isLoadingAttachments: boolean = false;

  constructor(
    private uploaderService: UploaderService,
  ) { }

  ngOnInit(): void {
    this.initFieldsValues();
  }

  private initFieldsValues(): void {
    for (let field of this.fields) {
      this.values[field.name] = "";
    }
    this.values["period"] = [new Date()];
    this.values["attachments"] = [];
  }

  public initFieldsValuesFromData(data: any): void {
    for (let field of this.fields) {
      this.values[field.name] = data[field.name];
    }
    if (data["period"]) {
      this.values["period"] = data["period"];
    }
    if (data["attachments"]) {
      this.values["attachments"] = data["attachments"];
    }
  }

  public getIconFromAttachmentUrl(extension: string): string {

    if (["png", "jpg", "jpeg", "gif", "svg", "heic"].includes(extension)) {
      return "image";
    } else if (["mp4", "avi", "mov", "wmv"].includes(extension)) {
      return "video";
    } else if (["mp3", "wav", "ogg"].includes(extension)) {
      return "music";
    } else {
      return "file";
    }
  }

  public downloadDocument(url: string): void {
    window.open(url, "_blank");
  }

  public getFieldOptions(field: CbCustomField): { label: string, value: string }[] {
    return field.options?.map(option => ({ label: option, value: option })) ?? [];
  }

  public deleteDocument(att: any): void {
    this.values["attachments"] = this.values["attachments"].filter((a: any) => a.url !== att.url);
  }

  public uploadFile(): void {
    if (this.disabled) return;
    this.isLoadingAttachments = true;
    // open file dialog
    let input = document.createElement('input');
    input.type = 'file';
    input.onchange = async (e) => {
      if ((e.target as HTMLInputElement).files) {
        const files = Array.from((e.target as HTMLInputElement).files ?? []);
        try {
          const resp = await lastValueFrom(this.uploaderService.putFileGoogle(files[0], uuid.v4() + "/" + files[0].name));
          const newAtt = {
            url: resp.url,
            name: files[0].name,
            extension: files[0].name.split('.').pop() ?? "",
          }
          this.values["attachments"] = [...this.values["attachments"], newAtt];
          this.isLoadingAttachments = false;
        } catch (error) {
          this.isLoadingAttachments = false;
        }
      }
    }
    input.click();


  }
}
