import { Component, Input, OnInit } from '@angular/core';

export enum CbCustomFieldType {
  text,
  select,
  boolean
}

export interface CbCustomField {
  name: string;
  label: string;
  isActive: boolean;
  isPublic: boolean;
  canDelete: boolean;
  type: CbCustomFieldType;
  options?: string[];
}

@Component({
  selector: 'app-fields-customization-widget',
  templateUrl: './fields-customization-widget.component.html',
  styleUrls: ['./fields-customization-widget.component.scss']
})
export class FieldsCustomizationWidgetComponent implements OnInit {
  @Input() public inputFields: CbCustomField[] = [];

  public readonly fieldTypeOptions: { value: CbCustomFieldType, label: string }[] = [
    { value: CbCustomFieldType.text, label: 'ENTITY.FIELD.TYPES.TEXT' },
    { value: CbCustomFieldType.select, label: 'ENTITY.FIELD.TYPES.CHOICE' },
  ];

  public fieldTypes: typeof CbCustomFieldType = CbCustomFieldType;

  public fields: CbCustomField[] = [];

  constructor() { }

  ngOnInit(): void {
    this.fields = this.inputFields;
  }

  public addNewField(): void {
    this.fields.push({ name: '', label: '', isActive: true, isPublic: true, canDelete: true, type: CbCustomFieldType.text });
  }

  public addOption(index: number): void {
    this.fields[index].options?.push('');
  }

  public fieldTypeChanged(idx: number): void {
    const type = this.fields[idx].type;
    if (type === CbCustomFieldType.select) {
      this.fields[idx].options = [''];
    } else {
      this.fields[idx].options = undefined;
    }
  }

  public deleteField(index: number): void {
    if (this.fields[index].canDelete) {
      this.fields.splice(index, 1);
    }
  }

  public deleteFieldOptions(fieldIndex: number, optionIndex: number): void {
    this.fields[fieldIndex].options?.splice(optionIndex, 1);
  }

  public choiceTrackByFn(index: any, choice: string) {
    return index;
  }
}
