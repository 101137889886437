import { lastValueFrom } from "rxjs";
import { UserPresenter } from "./presenters/userPresenter";
import { UsersPresenter } from "./presenters/usersPresenter";
import { User } from "./user";
import { Workspace, WorkspaceBasicAdditionalPropertie, WorkspaceBasicSchemaAction } from "./workspace";

export class UserManager {

    // Singleton for data manager
    private static instance: UserManager;
    private userPresenter = new UserPresenter();
    private usersPresenter = new UsersPresenter();

    public users: Workspace["users"] | undefined = undefined;
    public user: User | undefined = undefined;

    public get myPrivileges(): { [key: string]: boolean } {
        return this.users?.find((user) => user.id === this.user?.id)?.privileges ?? {};
    }

    public get isAdmin(): boolean {
        return this.myPrivileges['admin'] ?? false;
    }

    public get id(): string {
        return this.user?.id ?? "";
    }

    public get email(): string {
        return this.user?.email ?? "";
    }

    public static getInstance(): UserManager {
        if (!UserManager.instance) {
            UserManager.instance = new UserManager();
        }
        return UserManager.instance;
    }

    public async loadUser(force = false): Promise<void> {
        if (!this.user || force) {
            const result = (this.userPresenter.getDecodedAccessToken());
            this.user = result ?? undefined;
        }
    }

    public async loadUsers(force = false): Promise<void> {
        if (!this.users || force) {
            this.loadUser();
            const result = await lastValueFrom(this.usersPresenter.getManyRequested({}).promise);
            this.users = result.main as Workspace["users"];
        }
    }



}