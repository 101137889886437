<div class="cb-page-content">
  <div class="cb-page-header">
    <cb-topbar [backButton]="true" (click)="goBack()"></cb-topbar>
  </div>
  <div class="cb-page">
    <div class="cb-entity-info-section" *ngIf="unit">
      <div class="cb-entity-header">
        <h3>{{unit.name}}</h3>
        <div class="cb-entity-buttons">
          <cb-button [routerLink]="'lifecycles'" type="accent" iconPos="right"
            icon="chevron-forward">{{"PHASE.LIFECYCLES" | translate}}</cb-button>
        </div>
      </div>
      <ul>
        <li *ngIf="unit?.identifier">
          <span>{{"ENTITY.FIELDS.IDENTIFIER" | translate}}</span>
          <p>{{unit.identifier}}</p>
        </li>
        <ng-container *ngFor="let field of fields">
          <ng-container *ngIf="field.isActive">
            <li>
              <span>{{field.label}}</span>
              <p>{{getUnitProperty(field.name)}}</p>
            </li>
          </ng-container>
        </ng-container>
      </ul>
    </div>
  </div>
</div>