<div class="cb-user-container" [ngClass]="'size-'+size+' shape-'+shape">
  <div class="cb-user-profile">
    <ng-container *ngIf="hasProfilePicture; else InitialsTemplate">
      <div class="cb-user-image" [style.background-image]="'url('+picture+')'"></div>
    </ng-container>
    <ng-template #InitialsTemplate>
      <div class="cb-user-initials">{{initials}}</div>
    </ng-template>

    <ng-container *ngIf="hasNotifications">
      <div class="cb-user-notification"></div>
    </ng-container>
  </div>

  <ng-container *ngIf="username && username.length>0">
    <div class="cb-user-name">
      <p>{{username}}</p>
      <span>{{workspaceName}}</span>
    </div>
  </ng-container>
</div>
