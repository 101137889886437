/// IMPORTS
import {
  WorkspaceUserGetQuery,
  WorkspaceUserGetResponse,
  WorkspaceUserPostBody,
  WorkspaceUserPostResponse,
  WorkspaceUserPutBody,
  WorkspaceUserPutResponse,
  WorkspaceUserDeleteQuery,
  WorkspaceUserDeleteResponse,
  WorkspaceUsersGetQuery,
  WorkspaceUsersGetResponse,
  WorkspaceUserOrderPutBody,
  WorkspaceUserOrderPutResponse,
} from 'generatedInterfaces';
import { UsersModel } from 'src/app/classes/models/usersModel';
import { Workspace } from '../workspace';
import * as uuid from 'uuid';
import { Observable } from 'rxjs/internal/Observable';

export class UsersPresenter {
  /// PROPERTIES
  private model: UsersModel = new UsersModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceUserOrderPut
  public changeOrderRequested(
    obj: WorkspaceUserOrderPutBody
  ): Observable<WorkspaceUserOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspaceUsersGet
  public getManyRequested(obj: WorkspaceUsersGetQuery): {
    local: Workspace['users'];
    promise: Observable<WorkspaceUsersGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspaceUserDelete
  public deleteOneRequested(
    obj: WorkspaceUserDeleteQuery
  ): Observable<WorkspaceUserDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspaceUserPut
  // PUT BODY START //
  public putOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceUserPutResponse> {
    const obj = {
      id: values['id'],
      // USERNAME UPDATE
      username: values['username'],
      // EMAIL UPDATE
      email: values['email'],
      // INVITATIONCODE UPDATE
      invitationCode: values['invitationCode'],
      // PRIVILEGES UPDATE
      privileges: values['privileges'],
      // ORDER UPDATE
      order: values['order'],
      // CREATEDAT UPDATE
      createdAt: values['createdAt'],
      // UPDATEDAT UPDATE
      updatedAt: values['updatedAt'],
      // DELETEDAT UPDATE
      deletedAt: values['deletedAt'],
      // PUT BODY FIELDS
    };
    return this.model.putOne(<any>obj);
  }
  // PUT BODY END //

  // workspaceUserPost
  // POST BODY START //
  public postOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceUserPostResponse> {
    const obj = {
      id: uuid.v4(),
      // USERNAME CREATE
      username: values['username'],
      // EMAIL CREATE
      email: values['email'],
      // INVITATIONCODE CREATE
      invitationCode: values['invitationCode'],
      // PRIVILEGES CREATE
      privileges: values['privileges'],
      // ORDER CREATE
      order: values['order'],
      // CREATEDAT CREATE
      createdAt: values['createdAt'],
      // UPDATEDAT CREATE
      updatedAt: values['updatedAt'],
      // DELETEDAT CREATE
      deletedAt: values['deletedAt'],
      // POST BODY FIELDS
    };
    return this.model.postOne(<any>obj);
  }
  // POST BODY END //

  // workspaceUserGet
  public getOneRequested(obj: WorkspaceUserGetQuery): {
    local: Workspace['users'][0];
    promise: Observable<WorkspaceUserGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
