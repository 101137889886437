import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { lastValueFrom } from 'rxjs';
import { DataManager } from 'src/app/classes/dataManager';
import { UnitsPresenter } from 'src/app/classes/presenters/unitsPresenter';
import { UnitsManager } from 'src/app/classes/unitsManager';
import { UserManager } from 'src/app/classes/userManager';
import { CbDialogService } from 'src/app/widgets/cb-dialog/cb-dialog-service/cb-dialog.service';
import { SettingsPopoverItem } from 'src/app/widgets/cb-popover/cb-settings-popover/cb-settings-popover.component';
import { CreateEditEntityDialogComponent } from '../../dialogs/create-edit-entity-dialog/create-edit-entity-dialog.component';
import { EditEntityActionsDialogComponent } from '../../dialogs/edit-entity-actions-dialog/edit-entity-actions-dialog.component';
import { EditEntityPropertiesDialogComponent } from '../../dialogs/edit-entity-properties-dialog/edit-entity-properties-dialog.component';
import { QrcodeDialogComponent } from '../../dialogs/qrcode-dialog/qrcode-dialog.component';
import { PHASES_LIST, PhaseType } from '../../phases.module';
import * as Fuse from 'fuse.js';
import { CbToastService } from 'src/app/widgets/cb-toast/cb-toast.service';
import { LifecyclesManager } from 'src/app/classes/lifecyclesManager';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-phase-entities-view',
  templateUrl: './phase-entities-view.component.html',
  styleUrls: ['./phase-entities-view.component.scss']
})
export class PhaseEntitiesViewComponent implements OnInit {
  private readonly phases = PHASES_LIST;
  public readonly entitiesPerPage: number = 25;
  public readonly tableRowSettings: SettingsPopoverItem[] = [
    { name: 'qrcode', icon: 'qrcode', label: 'ENTITY.DOWNLOAD_QRCODE', type: 'default' },
    { name: 'copylink', icon: 'copy', label: 'ENTITY.COPY_LINK', type: 'default' },
  ];

  public unitsPresenter = new UnitsPresenter();
  public showOptions: number[] = [2, 25, 50];

  public loading: boolean = false;
  public entities: any[] = [];

  public currentPhase: PhaseType = PhaseType.winemaking;

  public fields: any[] = [];
  public search: string = '';


  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly dialogService: CbDialogService
  ) {
    // Get current route data
    this.route.data.subscribe(data => {
      this.currentPhase = data["phase"];
    });
  }

  ngOnInit(): void {
    this.loadFields(true);
  }

  private loadFields(force = false) {
    DataManager.getInstance().fromPhasePropertiesToFields(this.currentPhase).then(fields => {
      this.fields = fields;
      this.loadEntities(force);
    });
  }



  public get isAdmin(): boolean {
    const admin = UserManager.getInstance().isAdmin
    return admin;
  }

  public get pageIcon(): string {
    return this.phases.find(p => p.type === this.currentPhase)?.icon ?? "";
  }

  public get pageTitle(): string {
    return this.phases.find(p => p.type === this.currentPhase)?.name ?? "";
  }

  private async loadEntities(force = false): Promise<void> {
    this.loading = true;
    try {
      await UnitsManager.getInstance().loadUnits(force);
      const units = UnitsManager.getInstance().unitsFromType(this.currentPhase as PhaseType);
      this.entities = units.map(u => {
        return {
          id: u.id,
          assetId: u.assetId,
          name: u.name,
          identifier: u.identifier,
          isSingle: u.isSingle,
          unitType: u.unitType,
          order: u.order ?? 0,
          properties: u.properties.reduce((acc, p) => {
            const name = p.name as string;
            (<any>acc)[name] = p.value;
            return acc;
          }, {}),
          updatedAt: u.updatedAt,
          createdAt: u.createdAt
        }
      }).sort((a, b) => a.order - b.order);
      this.loading = false;
    } catch (error) {
      console.error(error);
      this.loading = false;
    }
  }

  public createNewEntity(): void {
    this.dialogService.openDialog({
      component: CreateEditEntityDialogComponent,
      data: {
        type: this.currentPhase
      }
    }, {
      afterClose: (result) => {
        if (result && result.id)
          this.loadEntities();
      }
    });
  }

  public editEntity(entity: any): void {
    this.dialogService.openDialog({
      component: CreateEditEntityDialogComponent,
      data: {
        entity: entity,
        type: this.currentPhase
      }
    }, {
      afterClose: (result) => {
        if (result && result.id)
          this.loadEntities();
      }
    });
  }

  public async getQRCodeString(entity: any): Promise<string> {
    await (LifecyclesManager.getInstance().loadLifecycles(true));
    const lifecycles = LifecyclesManager.getInstance().lifecyclesFromUnitId(entity.id);
    const url = environment.websiteUrl + this.router.createUrlTree([lifecycles[0].assetId]).toString();
    return url
  }

  public editEntityProperties(): void {
    if (this.isAdmin) {
      this.dialogService.openDialog({
        component: EditEntityPropertiesDialogComponent,
        data: {
          type: this.currentPhase
        }
      }, {
        afterClose: (result) => {
          if (result) {
            this.loadFields(false);
          }
        }
      });
    }
  }

  public editEntityActions(): void {
    if (this.isAdmin) {
      this.dialogService.openDialog({
        component: EditEntityActionsDialogComponent,
        data: {
          type: this.currentPhase
        }
      }, {
        afterClose: (result) => {
          if (result)
            this.loadEntities(true);
        }
      });
    }
  }

  public async settingClicked(option: string, entity: any): Promise<void> {
    const str = await this.getQRCodeString(entity);
    switch (option) {
      case 'qrcode':

        this.dialogService.openDialog({
          component: QrcodeDialogComponent,
          data: {
            title: entity.name,
            subtitle: entity.identifier,
            qr: str
          }
        });
        break;
      case 'copylink':
        navigator.clipboard.writeText(str).then(() => {
          CbToastService.instance.showMessage({
            title: 'COMMON.SUCCESS',
            message: "ENTITY.COPY_LINK_SUCCESS",
            icon: 'alert-circle-sharp',
            type: 'success'
          });
        });

        break;

    }
  }

  public tableSort(event: SortEvent): void {
    if (event.data && event.field && event.order) {
      event.data.sort((data1, data2): number => {

        let value1 = data1[event.field!] ?? (event.field == "updatedAt" ? new Date(data1["createdAt"]) : "");
        let value2 = data2[event.field!] ?? (event.field == "updatedAt" ? new Date(data2["createdAt"]) : "");

        if (event.field == "updatedAt") {
          return (new Date(value1).getTime() - new Date(value2).getTime()) * event.order!;
        }

        if (event.field?.includes("properties.")) {
          const prop = event.field.split(".")[1];
          value1 = data1["properties"][prop] ?? "";
          value2 = data2["properties"][prop] ?? "";
        }

        // if value1 is not a number, then compare as string
        if (isNaN(value1 as any) && isNaN(value2 as any)) {
          return value1.localeCompare(value2) * event.order!;
        } else {
          // if value1 is a number, then compare as number
          return (value1 - value2) * event.order!;
        }
      });
    }
  }

  public searchNow(): void {
    if (this.search.length < 3) {
      this.loadEntities();
    } else {
      const items = this.entities;
      const fuse = new Fuse.default(items, {
        keys: ['identifier', 'name'],
        fieldNormWeight: 0.5,
        findAllMatches: true
      });
      this.entities = fuse.search(this.search).map((result) => result.item);
    }
  }
}
