import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { UserPresenter } from 'src/app/classes/presenters/userPresenter';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PrivilegesService } from 'src/app/services/privileges.service';
import { PHASES_LIST } from 'src/app/modules/phases/phases.module';
import { DataManager } from 'src/app/classes/dataManager';
import { UsersPresenter } from 'src/app/classes/presenters/usersPresenter';
import { UserManager } from 'src/app/classes/userManager';

@Component({
  selector: 'cb-sidebar',
  templateUrl: './cb-sidebar.component.html',
  styleUrls: ['./cb-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CbSidebarComponent implements OnInit {
  public phases = PHASES_LIST;

  private url: string = "";
  private _isAdmin: boolean = false;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.url = this.router.url;
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        const split: string[] = this.url.split("/");
        this.cdr.detectChanges();
      }
    });
    this.loadData();
  }

  private loadData() {

    UserManager.getInstance().loadUsers().then(() => {
      this._isAdmin = UserManager.getInstance().isAdmin;
      this.cdr.detectChanges();
    }).catch(() => {
      console.error("Error loading users");
    });

    DataManager.getInstance().loadBasic().then(() => {
      const enabledUnits = DataManager.getInstance().enabledUnits;
      this.phases = PHASES_LIST.filter((phase) => enabledUnits.includes(phase.type));
    }).catch(() => {
      console.error("Error loading basic data");
    });
  }

  public get isAdmin(): boolean {
    return this._isAdmin;
  }

  public isRouteActive(route: string): boolean {
    return this.url.includes(route);
  }

  public checkPrivilege(privilege?: keyof PrivilegesService["privileges"]): boolean {
    return !privilege || PrivilegesService.instance.privileges.admin || PrivilegesService.instance.privileges[privilege];
  }
}
