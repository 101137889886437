/// IMPORTS
import {
  WorkspaceLifecycleGetQuery,
  WorkspaceLifecycleGetResponse,
  WorkspaceLifecyclePostBody,
  WorkspaceLifecyclePostResponse,
  WorkspaceLifecyclePutBody,
  WorkspaceLifecyclePutResponse,
  WorkspaceLifecycleDeleteQuery,
  WorkspaceLifecycleDeleteResponse,
  WorkspaceLifecyclesGetQuery,
  WorkspaceLifecyclesGetResponse,
  WorkspaceLifecyclePostsBody,
  WorkspaceLifecyclePostsResponse,
  WorkspaceLifecyclePutsBody,
  WorkspaceLifecyclePutsResponse,
  WorkspaceLifecycleDeletesQuery,
  WorkspaceLifecycleDeletesResponse,
  WorkspaceLifecycleOrderPutBody,
  WorkspaceLifecycleOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Workspace } from '../workspace';

export class LifecyclesOfflineEndpoint {
  /// PROPERTIES

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // Delete a local entity
  public delete(obj: Workspace['lifecycles'][0]) {
    const lifecycles = this.getMany();
    lifecycles.splice(
      lifecycles.findIndex((entity) => {
        if (entity.id === obj.id) {
          return true;
        }
        return false;
      }),
      1
    );
    this.setEntities(lifecycles);
  }

  // Push or update an entity
  public pushOrUpdate(obj: Workspace['lifecycles'][0]): void {
    const lifecycles = this.getMany();
    const index = lifecycles.findIndex((entity) => entity.id === obj.id);
    if (index === -1) {
      lifecycles.push(obj);
    } else {
      lifecycles[index] = obj;
    }
    this.setEntities(lifecycles);
  }

  // Get all the local entities
  public getMany(obj?: any): Workspace['lifecycles'] {
    if (localStorage.getItem('lifecycles') === null) {
      return [];
    }
    let entities = JSON.parse(
      localStorage.getItem('lifecycles') as any
    ) as Workspace['lifecycles'];

    if (obj) {
      return entities.filter((entity) => {
        let hasProperties = false;
        for (const key in obj) {
          hasProperties = true;
          if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
            if ((<any>entity)[key] === (<any>obj)[key]) {
              return true;
            }
          }
        }
        if (!hasProperties) return true;
        return false;
      });
    }

    return entities;
  }

  // Get one of the local entities
  public getOne(obj: any): Workspace['lifecycles'][0] {
    let lifecycles = this.getMany();
    // Filter for all the value of obj
    lifecycles = lifecycles.filter((entity) => {
      let hasProperties = false;
      for (const key in obj) {
        hasProperties = true;
        if (obj.hasOwnProperty(key) && entity.hasOwnProperty(key)) {
          if ((<any>entity)[key] === (<any>obj)[key]) {
            return true;
          }
        }
      }
      if (!hasProperties) return true;
      return false;
    });

    return lifecycles[0];
  }

  // Set the entire entities array
  public setEntities(obj: Workspace['lifecycles']): void {
    localStorage.setItem('lifecycles', JSON.stringify(obj));
  }

  /// CUSTOM METHODS

  ///
}
