import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

export interface SettingsPopoverItem{
  icon?: string;
  name: string;
  label: string;
  type: "danger" | "warning" | "info" | "success" | "default";
}

@Component({
  selector: 'cb-settings-popover',
  templateUrl: './cb-settings-popover.component.html',
  styleUrls: ['./cb-settings-popover.component.scss']
})
export class CbSettingsPopoverComponent implements OnInit {

  @Input() settingsList: SettingsPopoverItem[] = [];
  @Output() settingClicked: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild("popover") popover!: OverlayPanel;

  constructor() { }

  ngOnInit(): void {
  }

  public toggle(event: Event): void{
    this.popover.toggle(event);
  }

}
