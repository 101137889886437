<div class="cb-input-wrapper" [class.disabled]="disabled">
  <div class="cb-input-container">
    <div class="cb-input-prefix has-children">
      <ion-icon name="search"></ion-icon>
    </div>
    <div class="cb-input">
      <input [autocomplete]="autocomplete" [placeholder]="placeholder" [(ngModel)]="value" type="text" (input)="input()"
        (change)="change()" />
    </div>
  </div>
</div>
