import {
  Routes
} from "@angular/router";
import {
  AuthGuard
} from "./guards/auth.guard";



export const routes: Routes = [
  {
    path: "",
    redirectTo: "/home",
    pathMatch: "full",
  },
  // AUTHENTICATION
  {
    path: 'auth',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    data: {
      roles: []
    }
  },
  {
    path: "home",
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
    data: {
      roles: []
    }
  },
  // ADD ROUTES HERE
  {
    path: "tracking",
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/phases/phases.module').then(m => m.PhasesModule),
  },
  {
    path: "users",
    canActivate: [AuthGuard],
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
  }
];
