<cb-dialog [width]="'400px'" [closeButton]="false">
    <ng-container Title>
        <div class="cb-dialog-entity-title">
            <h4>{{((currentStep==0)? "ENTITY.ADDING_ACTION" : selectedActionName) | translate}}</h4>
            <div class="cb-dialog-info">
                <p>{{unit?.name}}</p>
                <p>{{unit?.identifier}}</p>
            </div>
        </div>
    </ng-container>

    <ng-container Content>
        <ng-container *ngIf="currentStep==0">
            <cb-select appendTo="body" [label]="'ENTITY.ACTION' | translate"
                [placeholder]="'ENTITY.SELECT_ACTION' | translate" [options]="actionsOptions"
                [(ngModel)]="selectedAction"></cb-select>
        </ng-container>
        <ng-container *ngIf="currentStep==1">
            <action-form-widget #fieldsComponent [fields]="selectedActionFields"></action-form-widget>
        </ng-container>
    </ng-container>


    <ng-container LeftButtons>
        <ng-container *ngIf="currentStep==0">
            <cb-button (click)="cancel()" type="neutral">{{"COMMON.CANCEL" | translate}}</cb-button>
        </ng-container>
        <ng-container *ngIf="currentStep==1">
            <cb-button (click)="previousStep()" type="neutral">{{"COMMON.PREVIOUS" | translate}}</cb-button>
        </ng-container>
    </ng-container>

    <ng-container RightButtons>
        <ng-container *ngIf="currentStep==0">
            <cb-button (click)="nextStep()" type="primary" [disabled]="!canGoForward">{{"COMMON.NEXT" |
                translate}}</cb-button>
        </ng-container>
        <ng-container *ngIf="currentStep==1">
            <cb-button-loading (submit)="submit($event)" type="success" [disabled]="!canSubmit">{{"COMMON.CREATE" |
                translate}}</cb-button-loading>
        </ng-container>
    </ng-container>
</cb-dialog>