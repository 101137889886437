import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserPresenter } from 'src/app/classes/presenters/userPresenter';
import { CbDialogFormService } from '../../cb-dialog-form/service/cb-dialog-form.service';
import { CbButtonType } from '../../cb-form-widgets/cb-button/cb-button.component';
import { CbStandardForm } from '../../cb-form/cb-form-interfaces';
import { CbToastService } from '../../cb-toast/cb-toast.service';

@Component({
  selector: 'cb-topbar',
  templateUrl: './cb-topbar.component.html',
  styleUrls: ['./cb-topbar.component.scss'],
})
export class CbTopbarComponent implements OnInit {
  @Input() public title = '';
  @Input() public loading = false;
  @Input() public backButton = false;
  @Input() public username = '';

  @Input() public goBackLocation: boolean = false;

  @Output('goBack') onGoBack: EventEmitter<void> = new EventEmitter<void>();
  @Output('changePassword') onChangePassword: EventEmitter<void> =
    new EventEmitter<void>();
  @Output('logout') onLogout: EventEmitter<void> = new EventEmitter<void>();

  constructor(private readonly location: Location,
    private readonly router: Router,
    private readonly dialogFormService: CbDialogFormService) { }

  ngOnInit(): void {
    this.loadUser();
  }

  private loadUser(): void {
    const userPresenter = new UserPresenter();
    const user = userPresenter.getDecodedAccessToken();
    const username = user?.username ?? "";
    // Capitalize username
    this.username = username.charAt(0).toUpperCase() + username.slice(1);
  }

  public goBack(): void {
    if (this.goBackLocation) {
      this.location.back();
    } else {
      this.onGoBack.emit();
    }
  }

  public changePassword(): void {
    this.dialogFormService.openFormDialog(CHANGE_PASSWORD_FORM)
  }

  public async logout(): Promise<void> {
    const userPresenter = new UserPresenter();
    await userPresenter.logoutRequested();
    this.onLogout.emit();

    this.router.navigate(['/auth/login']);
  }
}

export const CHANGE_PASSWORD_FORM: CbStandardForm = {
  title: "SETTINGS.USERS.CHANGE_PASSWORD",
  rows: [
    { columns: [{ field: "oldPassword", placeholder: "SETTINGS.USERS.FIELDS.PLACEHOLDERS.OLD_PASSWORD", label: "SETTINGS.USERS.FIELDS.LABELS.OLD_PASSWORD", type: "password" }] },
    { columns: [{ field: "password", placeholder: "SETTINGS.USERS.FIELDS.PLACEHOLDERS.PASSWORD", label: "SETTINGS.USERS.FIELDS.LABELS.PASSWORD", type: "password" }] },
    {
      columns: [{
        field: "passwordConfirm", placeholder: "SETTINGS.USERS.FIELDS.PLACEHOLDERS.PASSWORD_CONFIRM", label: "SETTINGS.USERS.FIELDS.LABELS.PASSWORD_CONFIRM", type: "password", validator: (value, values) => {
          return values["password"] == values["passwordConfirm"];
        }
      }]
    },
  ],
  submitButton: {
    label: "COMMON.SAVE",
    type: CbButtonType.success,
    show: true,
    onClick: (values: { [key: string]: any }) => {
      let userPresenter = new UserPresenter();
      userPresenter.changePasswordRequested({ password: values["oldPassword"], passwordConfirm: values["passwordConfirm"] }).subscribe({
        next: (value) => {
          CbToastService.instance.showMessage({ title: "COMMON.SUCCESS", message: "CHANGEPASSWORD.SUCCESS", icon: "alert-circle-sharp", type: "success" });
        }, error: (error) => {
          // SHOW ERROR
          CbToastService.instance.showMessage({ title: "COMMON.ERROR", message: error.error.message, icon: "alert-circle-sharp", type: "danger" });
        }, complete: () => {
        }
      });
    }
  }
}