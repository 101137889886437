import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserPresenter } from './classes/presenters/userPresenter';
import { PrivilegesService } from './services/privileges.service';
import { Translate } from './services/translate.service';
import { PrimeNGConfig } from 'primeng/api';
import { CbToastService } from './widgets/cb-toast/cb-toast.service';
import { DataManager } from './classes/dataManager';

interface SidebarItem {
  title: string,
  icon: string,
  url: string,
  privilege: keyof PrivilegesService["privileges"]
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {

  private userPresenter: UserPresenter = new UserPresenter();
  private url: string = "";
  public pageTitle: string = "";
  public isInitView: boolean = false;
  public showSidebar: boolean = false;

  private readonly pathsWithoutSidebar = ["auth"];

  public readonly sidebarItems: SidebarItem[] = [
    // ADD HERE ENTITIES
  ];

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cbToastService: CbToastService,
    private readonly primeNgConfig: PrimeNGConfig,
    private readonly translateService: TranslateService,
  ) {
    // Load PrimeNG translations
    this.translateService.get('primeng').subscribe(res => this.primeNgConfig.setTranslation(res));
  }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.url = event.url;
        this.isInitView = true;
        const split: string[] = this.url.split("/");
        this.showSidebar = !this.pathsWithoutSidebar.includes(split[1]);
      }
    });
  }

  ngAfterViewInit(): void {
  }

  public checkPrivilege(privilege: keyof PrivilegesService["privileges"]): boolean {
    return PrivilegesService.instance.privileges.admin || PrivilegesService.instance.privileges[privilege];
  }

  public get currentRoute(): string {
    return this.url;
  }

  public logout(): void {
    this.userPresenter.logoutRequested();
    // Redirect to login
    this.router.navigate(["/auth/login"]);
  }
}
