<cb-dialog [width]="'750px'">
    <ng-container Title>
        <div class="cb-dialog-entity-title">
            <h4>{{"ENTITY.NEW_LIFECYCLE" | translate:{phase: (currentPhase.name | translate)} }}</h4>
            <ng-container>
                <div class="cb-dialog-info">
                    <p>{{unit?.name}}</p>
                    <p>{{unit?.identifier}}</p>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-container Content>
        <cb-input [label]="'ENTITY.LIFECYCLE_NAME' | translate"
            [placeholder]="'ENTITY.LIFECYCLE_NAME_PLACEHOLDER' | translate" [(ngModel)]="name"></cb-input>

        <!-- SELECT ANCESTORS FORM -->
        <ng-container *ngIf="hasAncestors">
            <div class="cb-ancestors-form">
                <div class="cb-ancestors-title">
                    <cb-icon [name]="previousPhase.icon"></cb-icon>
                    <h6>{{previousPhase.name | translate}}</h6>
                </div>
                <p>{{"ENTITY.SELECT_LIFECYCLE_ANCESTOR" | translate:{ ancestorName: (previousPhase.name | translate) }
                    }}</p>

                <cb-search [placeholder]="'COMMON.SEARCH' | translate" [(ngModel)]="searchText"
                    (input)="searchResults()"></cb-search>

                <div class="cb-ancestors-search-result">
                    <table>
                        <tbody>
                            <ng-container
                                *ngFor="let lifecycle of (shownResults  | pagination:currentPage:resultsPerPage)">
                                <tr>
                                    <td>
                                        <cb-checkbox [(ngModel)]="lifecycle.selected"
                                            (change)="changeSelected()"></cb-checkbox>
                                    </td>
                                    <td>{{lifecycle.unitIdentifier}}</td>
                                    <td>{{lifecycle.unitName}}</td>
                                    <td>{{lifecycle.identifier}}</td>
                                    <td>{{"COMMON.FROM" | translate}} <b>{{lifecycle.period[0]?(lifecycle.period[0] |
                                            date):"-"}}</b> {{"COMMON.TO" | translate}}
                                        <b>{{lifecycle.period[1]?(lifecycle.period[1] |
                                            date):"-"}}</b>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <div class="cb-ancestors-paginator">
                        <p>

                            {{(selectedCount == 1 ? "COMMON.SELECTED1":"COMMON.SELECTED") | translate: {count:
                            selectedCount} }}
                        </p>
                        <cb-icon-button [type]="(canGoBack)? 'accent' : 'neutral'" icon="chevron-back"
                            [disabled]="!canGoBack" (click)="previousPage()"></cb-icon-button>
                        <cb-icon-button [type]="(canGoNext)? 'accent' : 'neutral'" icon="chevron-forward"
                            [disabled]="!canGoNext" (click)="nextPage()"></cb-icon-button>
                    </div>
                </div>
            </div>
        </ng-container>
    </ng-container>

    <ng-container RightButtons>
        <cb-button-loading (submit)="submit($event)">{{"COMMON.CREATE" | translate}}</cb-button-loading>
    </ng-container>
</cb-dialog>