import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LifecyclesManager } from 'src/app/classes/lifecyclesManager';
import { UnitsManager } from 'src/app/classes/unitsManager';
import { Workspace } from 'src/app/classes/workspace';
import { CbDialogService } from 'src/app/widgets/cb-dialog/cb-dialog-service/cb-dialog.service';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { CloseLifecycleDialogComponent } from '../../dialogs/close-lifecycle-dialog/close-lifecycle-dialog.component';
import { NewActionDialogComponent } from '../../dialogs/new-action-dialog/new-action-dialog.component';
import { NewLifecycleDialogComponent } from '../../dialogs/new-lifecycle-dialog/new-lifecycle-dialog.component';
import * as uuid from 'uuid';
import { LifecyclesPresenter } from 'src/app/classes/presenters/lifecyclesPresenter';
import { TranslateService } from '@ngx-translate/core';
import { CbToastService } from 'src/app/widgets/cb-toast/cb-toast.service';
import { ActionFormWidgetComponent } from '../../widgets/action-form-widget/action-form-widget.component';
import { CbCustomField } from '../../widgets/fields-customization-widget/fields-customization-widget.component';
import { DataManager } from 'src/app/classes/dataManager';
import { CancelLifecycleDialogComponent } from '../../dialogs/cancel-lifecycle-dialog/cancel-lifecycle-dialog.component';

const MOBILE_MAX_WIDTH = 1024;

@Component({
  selector: 'app-phase-entity-lifecycles-view',
  templateUrl: './phase-entity-lifecycles-view.component.html',
  styleUrls: ['./phase-entity-lifecycles-view.component.scss']
})
export class PhaseEntityLifecyclesViewComponent implements OnInit {

  public isMobile!: boolean;

  public lifecycles: Workspace["lifecycles"] = [];
  public unit: Workspace["units"][0] | undefined = undefined;
  public currentPhase: string = "";
  private id: string = "";
  public isLoading: boolean = false;

  public selectedLifecycleIndex: number = -1;
  public selectedActionIndex?: number = undefined;

  public actionsOptions: { value: string, label: string, fields: CbCustomField[] }[] = [];

  @ViewChild("fieldsComponent") fieldsComponent: ActionFormWidgetComponent | undefined = undefined;

  constructor(
    private readonly router: Router,
    private readonly dialogService: CbDialogService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
  ) {
    // Get current route data
    this.route.data.subscribe(data => {
      this.currentPhase = data["phase"];
      this.actionsOptions = DataManager.getInstance().getActionsFromType(data["phase"]);
    });
    this.route.paramMap.subscribe(params => {
      const id = params.get("id");
      this.id = id || "";
    });

    // Check if the screen is mobile
    this.isMobile = window.innerWidth <= MOBILE_MAX_WIDTH;
  }

  ngOnInit(): void {
    this.isLoading = true;
    UnitsManager.getInstance().getUnitById(this.id).then((unit) => {
      this.unit = unit;
    });

    LifecyclesManager.getInstance().loadLifecycles(true).then(() => {
      this.isLoading = false;
      this.lifecycles = LifecyclesManager.getInstance().lifecyclesFromUnitId(this.id);
      if (this.lifecycles.length === 0 && this.unit?.isSingle) {
        this.newLifecycle();
      } else {
        this.selectedLifecycleIndex = this.lifecycles.length - 1;
      }
    });
  }

  public get actions(): Workspace["lifecycles"][0]["actions"] {
    const lifecycle = this.lifecycles[this.selectedLifecycleIndex];
    if (lifecycle) {
      return lifecycle.actions ?? [];
    }
    return [];
  };

  public get selectedActionFields(): CbCustomField[] {
    const action = this.action;
    if (action) {
      const actionOption = this.actionsOptions.find((option) => option.value === action.name);
      if (actionOption) {
        return actionOption.fields;
      }
    }
    return [];
  };

  public get action(): Workspace["lifecycles"][0]["actions"][0] | undefined {
    const lifecycle = this.lifecycles[this.selectedLifecycleIndex];
    if (lifecycle) {
      const action = lifecycle.actions?.[this.selectedActionIndex ?? 0];
      if (action) {
        return action;
      }
    }
    return undefined;
  }

  public get canSubmitActionForm(): boolean {
    return (this.lifecycle != undefined && this.lifecycle.status === "inprogress") && (this.fieldsComponent?.isLoadingAttachments ?? false) === false;
  }

  public get lifecycle(): Workspace["lifecycles"][0] | undefined {
    return this.lifecycles[this.selectedLifecycleIndex] ?? undefined;
  }

  public get canEditSelectedLifecycle(): boolean {
    const indexOfLastInprogress = this.lifecycles.findIndex((lifecycle) => lifecycle.status === "inprogress");
    return this.selectedLifecycleIndex>=0 && indexOfLastInprogress === this.selectedLifecycleIndex;
  }

  public get canCreateNewLifecycle(): boolean {
    for (let i = 0; i < this.lifecycles.length; i++) {
      if (this.lifecycles[i].status === "inprogress") {
        return false
      }
    }
    return true;
  }

  public get isSingleLifecycle(): boolean {
    return this.unit?.isSingle ?? false;
  }

  public goBackToActions(): void {
    this.selectedActionIndex = undefined;
  }

  public selectLifecycle(index: number): void {
    this.selectedLifecycleIndex = index;
    this.selectedActionIndex = undefined;
  }

  public selectAction(index: number): void {
    this.selectedActionIndex = index;
    // Otherwise the ViewChild is not existing
    setTimeout(() => {
      // Load and set values
      const action = this.action;
      if (action && this.fieldsComponent) {
        let values: { [key: string]: any } = {};
        values["period"] = [];
        for (let per of action.period ?? [])
          values["period"].push(new Date(per));

        values["attachments"] = action.attachments ?? [];
        values["name"] = action.name ?? "";
        for (let property of action.properties) {
          values[property.name] = property.value ?? "";
        }
        this.fieldsComponent.values = values;
      } else {
        this.selectedActionIndex = -1;
      }
    }, 0);
  }

  public newLifecycle(): void {
    if (this.canCreateNewLifecycle)
      this.dialogService.openDialog({
        component: NewLifecycleDialogComponent,
        data: {
          type: this.currentPhase,
          unitId: this.unit?.id ?? uuid.v4(),
          name: this.translate.instant("COMMON.LIFECYCLE") + " #" + (this.lifecycles.length + 1)
        }
      }, {
        afterClose: async (result) => {
          if (result && result.id) {
            await LifecyclesManager.getInstance().loadLifecycles(true);
            this.lifecycles = LifecyclesManager.getInstance().lifecyclesFromUnitId(this.id);
            this.selectLifecycle(this.lifecycles.length - 1);
          }
        }
      })
  }

  public newAction(): void {
    this.dialogService.openDialog({
      component: NewActionDialogComponent,
      data: {
        type: this.currentPhase,
        unitId: this.unit?.id ?? uuid.v4(),
        lifecycle: this.lifecycles[this.selectedLifecycleIndex]
      }
    }, {
      afterClose: async (result) => {
        if (result && result.id) {
          await LifecyclesManager.getInstance().loadLifecycles(true);
          this.lifecycles = LifecyclesManager.getInstance().lifecyclesFromUnitId(this.id);
          this.selectAction(this.actions.length - 1);
        }
      }
    })
  }

  public closeLifecycle(): void {
    console.log("close lifecycle", this.lifecycles[this.selectedLifecycleIndex]);
    this.dialogService.openDialog({
      component: CloseLifecycleDialogComponent,
      data: {
        type: this.currentPhase,
        unitId: this.unit?.id ?? uuid.v4(),
        lifecycle: this.lifecycles[this.selectedLifecycleIndex]
      }
    }, {
      afterClose: async (result) => {
        if (result && result.id) {
          await LifecyclesManager.getInstance().loadLifecycles(true);
          this.lifecycles = LifecyclesManager.getInstance().lifecyclesFromUnitId(this.id);
          if (this.unit?.isSingle) {
            this.selectLifecycle(0);
            this.selectAction(0);
          }
          else
            this.selectLifecycle(-1);
        
          console.log(this.selectedLifecycleIndex, this.selectedActionIndex);
        }
      }
    })
  }

  public cancelLifecycle(): void {
    this.dialogService.openDialog({
      component: CancelLifecycleDialogComponent,
      data: {
        type: this.currentPhase,
        unitId: this.unit?.id ?? uuid.v4(),
        lifecycle: this.lifecycles[this.selectedLifecycleIndex]
      }
    }, {
      afterClose: async (result) => {
        if (result && result.id) {
          await LifecyclesManager.getInstance().loadLifecycles(true);
          this.lifecycles = LifecyclesManager.getInstance().lifecyclesFromUnitId(this.id);
          this.selectLifecycle(-1);
          this.selectAction(-1);
        }
      }
    })
  }


  public async saveActionForm(callbacks: CbLoadingButtonEvent): Promise<void> {
    if (!this.canSubmitActionForm) {
      callbacks.error.next();
      return
    }

    try {
      const values = this.fieldsComponent?.values;

      const lifecycle = { ...this.lifecycles[this.selectedLifecycleIndex] };
      if (values) {

        const properties = Object.keys(values).filter((key) => key !== "period" && key !== "attachments" && key !== "name").map((key) => {
          return {
            name: key,
            value: values[key]
          };
        });

        const period = values["period"]?.filter((val: any) => val).map((date: Date) => date.toISOString()) ?? [];
        const attachments = values["attachments"] ?? [];

        const oldAction = this.action;

        for (let action of lifecycle.actions) {
          if (action.id === oldAction?.id) {
            action.attachments = attachments;
            action.period = period;
            action.attachments = values["attachments"] || [];
            action.updatedAt = new Date().toISOString();
            action.properties = properties || [];
            break;
          }
        }

        await LifecyclesManager.getInstance().updateOrInsertLifecycle({ ...lifecycle });

        callbacks.success.next();
      } else {
        callbacks.error.next();
      }
    } catch (error) {
      CbToastService.instance.showHttpError(error);
      callbacks.error.next();
    }
  }
}
