import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { DataManager } from "src/app/classes/dataManager";
import { UnitsManager } from "src/app/classes/unitsManager";
import { Workspace } from "src/app/classes/workspace";
import { CbDialogService } from "src/app/widgets/cb-dialog/cb-dialog-service/cb-dialog.service";
import { PhaseType } from "../../phases.module";


@Component({
  selector: 'app-phase-entity-view',
  templateUrl: './phase-entity-view.component.html',
  styleUrls: ['./phase-entity-view.component.scss']
})
export class PhaseEntityViewComponent implements OnInit {
  public currentPhase: string = "";
  private id: string = "";
  public unit: Workspace["units"][0] | undefined = undefined;

  public isLoading: boolean = false;
  public fields: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly dialogService: CbDialogService,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
  ) {
    // Get current route data
    this.route.data.subscribe(data => {
      this.currentPhase = data["phase"];
    });
    this.route.paramMap.subscribe(params => {
      const id = params.get("id");
      this.id = id || "";
    });
  }

  ngOnInit(): void {
    this.isLoading = true;
    UnitsManager.getInstance().getUnitById(this.id).then((unit) => {
      this.unit = unit;
      this.isLoading = false;
      DataManager.getInstance().fromPhasePropertiesToFields(this.currentPhase as PhaseType).then(fields => {
        this.fields = fields;
      });
    });
  }

  public goBack(): void {
    this.router.navigate(['..']);
  }

  getUnitProperty(name: string): string {
    let text: string = "-";

    if (this.unit) {
      text = this.unit.properties.find(p => p.name === name)?.value || "-";
    }

    return text;
  }

}
