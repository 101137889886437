import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { BasicPresenter } from '../classes/presenters/basicPresenter';
import { UserPresenter } from '../classes/presenters/userPresenter';
import { PrivilegesService } from '../services/privileges.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  public userPresenter: UserPresenter = new UserPresenter();
  public basicPresenter: BasicPresenter = new BasicPresenter();

  constructor(private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const data: any = route.data;

    const isAuthenticated = this.userPresenter.isAuthenticated();
    const confirmed = this.userPresenter.getDecodedAccessToken()?.confirmed;
    const hasBasic = this.basicPresenter.getSelectedRequested()?.length > 0;
    return true;
    /* if (isAuthenticated && confirmed && hasBasic) {
      return confirmed ?? false;
    } else {
      if (isAuthenticated && confirmed && !hasBasic) {
        this.router.navigate(['/auth/select-workspace']);
        return false;
      } else {
        this.router.navigate(['/auth/login']);
        return false;
      }
    } */
  }
}
