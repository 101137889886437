<div class="cb-page-topbar">
  <div class="cb-page-title">
    <ng-container *ngIf="backButton">
      <div class="cb-back-button">
        <cb-icon-button type="neutral" icon="chevron-back" (click)="goBack()"></cb-icon-button>
      </div>
    </ng-container>
    <ng-container *ngIf="!loading; else LoadingTemplate">
      <ng-container *ngIf="title && title.length>0; else ContentTemplate">
        <h3>{{title}}</h3>
      </ng-container>
      <ng-template #ContentTemplate>
        <ng-content></ng-content>
      </ng-template>
    </ng-container>
    <ng-template #LoadingTemplate>
      <cb-title-skeleton size="h3"></cb-title-skeleton>
    </ng-template>
  </div>
  <div class="cb-page-toolbar-container">
    <div class="cb-page-toolbar">
      <cb-topbar-user username="{{username}}" (logout)="logout()" (changePassword)="changePassword()">
      </cb-topbar-user>
    </div>
  </div>
</div>