import { Pipe, PipeTransform } from '@angular/core';
import { DataManager } from 'src/app/classes/dataManager';

@Pipe({
  name: 'actionsName',
})
export class ActionsNamePipe implements PipeTransform {
  transform(value: string): string {
    const lotsActions = DataManager.getInstance().getLotActions;
    let res = lotsActions.find((action) => action.name === value)?.label?.it || null;
    if (!res) {
      const winemakingsActions = DataManager.getInstance().getWinemakingActions;
      res = winemakingsActions.find((action) => action.name === value)?.label?.it || null;
    }

    if (!res) {
      const vineyardActions = DataManager.getInstance().getVineyardActions;
      res = vineyardActions.find((action) => action.name === value)?.label?.it || null;
    }

    return res || value;
  }
}
