import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export interface CbToastMessage {
  key?: string;
  title: string;
  icon?: string;
  message: string;
  duration?: number;
  type?: "primary" | "success" | "danger" | "warning" | "info";
}

@Injectable({
  providedIn: 'root'
})
export class CbToastService {
  public cbToastSubject: Subject<CbToastMessage> = new Subject<CbToastMessage>();

  static instance: CbToastService;

  constructor() {
    CbToastService.instance = this;
  }

  public showMessage(message: CbToastMessage): void {
    this.cbToastSubject.next(message);
  }

  public showHttpError(error: any): void {
    console.error(error);
    const message = error.message ?? error.error?.message ?? error.error ?? JSON.stringify(error) ?? '';
    this.showMessage({
      title: 'COMMON.ERROR',
      message: message,
      icon: 'alert-circle-sharp',
      type: 'danger',
    });
  }
}
