import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cb-title-skeleton',
  templateUrl: './cb-title-skeleton.component.html',
  styleUrls: ['./cb-title-skeleton.component.scss', '../cb-skeleton.scss']
})
export class CbTitleSkeletonComponent implements OnInit {
  @Input() public size: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" = "h1";

  constructor() { }

  ngOnInit(): void {
  }

}
