import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-qrcode-dialog',
  templateUrl: './qrcode-dialog.component.html',
  styleUrls: ['./qrcode-dialog.component.scss']
})
export class QrcodeDialogComponent implements OnInit {
  public qrCodeDownloadLink: SafeUrl = "";

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title?: string, subtitle?:string, qr: string },
    private readonly dialogRef: MatDialogRef<QrcodeDialogComponent>
  ) {
    if (!data.qr)
      this.close();
  }

  ngOnInit(): void {
  }

  public onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  public close(): void {
    this.dialogRef.close();
  }
}
