import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManager } from 'src/app/classes/dataManager';
import { LifecyclesManager } from 'src/app/classes/lifecyclesManager';
import { UnitsManager } from 'src/app/classes/unitsManager';
import { Workspace } from 'src/app/classes/workspace';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { PhaseType } from '../../phases.module';
import { TranslateService } from '@ngx-translate/core';
import { CbGlobalLoaderService } from 'src/app/widgets/cb-global-loader/cb-global-loader.service';

@Component({
  selector: 'app-cancel-lifecycle-dialog',
  templateUrl: './cancel-lifecycle-dialog.component.html',
  styleUrls: ['./cancel-lifecycle-dialog.component.scss']
})
export class CancelLifecycleDialogComponent implements OnInit {

  public unit: Workspace["units"][0] | undefined;
  public lifecycle: Workspace["lifecycles"][0] | undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      type: PhaseType,
      unitId: string,
      lifecycle: Workspace["lifecycles"][0]
    },
    private readonly dialogRef: MatDialogRef<CancelLifecycleDialogComponent>,
    private readonly globalLoaderService: CbGlobalLoaderService,
    private readonly translate: TranslateService
  ) {
    // TODO: Get data
    if (data && data.lifecycle) {
      this.lifecycle = data.lifecycle;
      UnitsManager.getInstance().getUnitById(data.unitId).then((unit) => {
        this.unit = unit;
      });
    }
  }

  ngOnInit(): void {
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public submit(callbacks: CbLoadingButtonEvent): void {
    if (this.lifecycle && this.unit) {

      this.globalLoaderService.showLoader(
        true,
        this.translate.instant("COMMON.UPDATING_BLOCKCHAIN"),
      );

      LifecyclesManager.getInstance().deleteLifecycle(this.lifecycle.id).then(() => {
        callbacks.success.next();
        setTimeout(() => {
          this.globalLoaderService.hideLoader();
          this.dialogRef.close({ id: this.lifecycle?.id });
        }, 200);
      }).catch((err) => {
        if (this.lifecycle)
          this.lifecycle.status = "inprogress";

        console.error(err);
        this.globalLoaderService.hideLoader();
        callbacks.error.next();
      });
    }
  }

}
