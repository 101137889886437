/// IMPORTS
import {
  WorkspaceUnitGetQuery,
  WorkspaceUnitGetResponse,
  WorkspaceUnitPostBody,
  WorkspaceUnitPostResponse,
  WorkspaceUnitPutBody,
  WorkspaceUnitPutResponse,
  WorkspaceUnitDeleteQuery,
  WorkspaceUnitDeleteResponse,
  WorkspaceUnitsGetQuery,
  WorkspaceUnitsGetResponse,
  WorkspaceUnitPostsBody,
  WorkspaceUnitPostsResponse,
  WorkspaceUnitPutsBody,
  WorkspaceUnitPutsResponse,
  WorkspaceUnitDeletesQuery,
  WorkspaceUnitDeletesResponse,
  WorkspaceUnitOrderPutBody,
  WorkspaceUnitOrderPutResponse,
} from 'generatedInterfaces';
import { UnitsModel } from 'src/app/classes/models/unitsModel';
import { Workspace } from '../workspace';
import * as uuid from 'uuid';
import { Observable } from 'rxjs/internal/Observable';

export class UnitsPresenter {
  /// PROPERTIES
  private model: UnitsModel = new UnitsModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceUnitOrderPut
  public changeOrderRequested(
    obj: WorkspaceUnitOrderPutBody
  ): Observable<WorkspaceUnitOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspaceUnitDeletes
  public deletesManyRequested(
    obj: WorkspaceUnitDeletesQuery
  ): Observable<WorkspaceUnitDeletesResponse> {
    return this.model.deletesMany(obj);
  }

  // workspaceUnitPuts
  public putsManyRequested(
    obj: WorkspaceUnitPutsBody
  ): Observable<WorkspaceUnitPutsResponse> {
    return this.model.putsMany(obj);
  }

  // workspaceUnitPosts
  public postsManyRequested(
    obj: WorkspaceUnitPostsBody
  ): Observable<WorkspaceUnitPostsResponse> {
    return this.model.postsMany(obj);
  }

  // workspaceUnitsGet
  public getManyRequested(obj: WorkspaceUnitsGetQuery): {
    local: Workspace['units'];
    promise: Observable<WorkspaceUnitsGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspaceUnitDelete
  public deleteOneRequested(
    obj: WorkspaceUnitDeleteQuery
  ): Observable<WorkspaceUnitDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspaceUnitPut
  // PUT BODY START //
  public putOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceUnitPutResponse> {
    const obj = {
      id: values['id'],
      // ASSETID UPDATE
      assetId: values['assetId'],
      // NAME UPDATE
      name: values['name'],
      // IDENTIFIER UPDATE
      identifier: values['identifier'],
      // PROPERTIES UPDATE
      properties: values['properties'],
      // UNITTYPE UPDATE
      unitType: values['unitType'],
      // ISSINGLE UPDATE
      isSingle: values['isSingle'],
      // ORDER UPDATE
      order: values['order'],
      // CREATEDAT UPDATE
      createdAt: values['createdAt'],
      // UPDATEDAT UPDATE
      updatedAt: values['updatedAt'],
      // DELETEDAT UPDATE
      deletedAt: values['deletedAt'],
      // PUT BODY FIELDS
    };
    return this.model.putOne(<any>obj);
  }
  // PUT BODY END //

  // workspaceUnitPost
  // POST BODY START //
  public postOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceUnitPostResponse> {
    const obj = {
      id: uuid.v4(),
      // ASSETID CREATE
      assetId: values['assetId'],
      // NAME CREATE
      name: values['name'],
      // IDENTIFIER CREATE
      identifier: values['identifier'],
      // PROPERTIES CREATE
      properties: values['properties'],
      // UNITTYPE CREATE
      unitType: values['unitType'],
      // ISSINGLE CREATE
      isSingle: values['isSingle'],
      // ORDER CREATE
      order: values['order'],
      // CREATEDAT CREATE
      createdAt: values['createdAt'],
      // UPDATEDAT CREATE
      updatedAt: values['updatedAt'],
      // DELETEDAT CREATE
      deletedAt: values['deletedAt'],
      // POST BODY FIELDS
    };
    return this.model.postOne(<any>obj);
  }
  // POST BODY END //

  // workspaceUnitGet
  public getOneRequested(obj: WorkspaceUnitGetQuery): {
    local: Workspace['units'][0];
    promise: Observable<WorkspaceUnitGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
