import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, EMPTY, throwError, BehaviorSubject } from "rxjs";
import { catchError, filter, take, switchMap } from "rxjs/operators";
import { UserPresenter } from "../classes/presenters/userPresenter";
import { BasicPresenter } from "../classes/presenters/basicPresenter";

@Injectable()
export class Interceptor implements HttpInterceptor {
  private userPresenter: UserPresenter = new UserPresenter();
  private basicPresenter: BasicPresenter = new BasicPresenter();
  constructor(
  ) { }

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let customReq: HttpRequest<any>;
    if (
      this.userPresenter.isAuthenticated()
    ) {
      let token = this.userPresenter.getAccessToken();
      customReq = this.addToken(request, token);
    } else {
      customReq = request;
    }

    return next.handle(customReq).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          console.error("401 ERROR");
          return this.handle401Error(request, next);
        } else {
          console.error(error);
          throw (error);
        }
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    const workspace = this.basicPresenter.getSelectedRequested();
    return request.clone({
      setHeaders: {
        workspace: workspace ? workspace : "",
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.userPresenter
        .tokenRequested({ refreshToken: this.userPresenter.getRefreshToken() })
        .pipe(
          switchMap((tokens: { accessToken: string, refreshToken: string }) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(tokens.accessToken);
            return next.handle(this.addToken(request, tokens.accessToken));
          }),
          catchError((err: HttpErrorResponse) => {
            this.isRefreshing = false;
            console.error(err);
            throw err;
          })
        );
    } else {
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }
}
