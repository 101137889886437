/// IMPORTS
import {
  WorkspaceBasicGetQuery,
  WorkspaceBasicGetResponse,
  WorkspaceBasicPostBody,
  WorkspaceBasicPostResponse,
  WorkspaceBasicPutBody,
  WorkspaceBasicPutResponse,
  WorkspaceBasicDeleteQuery,
  WorkspaceBasicDeleteResponse,
  WorkspaceBasicsGetQuery,
  WorkspaceBasicsGetResponse,
  WorkspaceBasicOrderPutBody,
  WorkspaceBasicOrderPutResponse,
} from 'generatedInterfaces';
import { BasicModel } from 'src/app/classes/models/basicModel';
import { Workspace } from '../workspace';
import * as uuid from 'uuid';
import { Observable } from 'rxjs/internal/Observable';

export class BasicPresenter {
  /// PROPERTIES
  private model: BasicModel = new BasicModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS
  public setSelectedRequested(id: string): void {
    this.model.setSelected(id);
  }
  public getSelectedRequested(): string {
    return this.model.getSelected();
  }
  public removeSelectedRequested(): void {
    this.model.removeSelected();
  }

  // workspaceBasicOrderPut
  public changeOrderRequested(
    obj: WorkspaceBasicOrderPutBody
  ): Observable<WorkspaceBasicOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspaceBasicsGet
  public getManyRequested(obj: WorkspaceBasicsGetQuery): {
    local: Workspace['basic'][];
    promise: Observable<WorkspaceBasicsGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspaceBasicDelete
  public deleteOneRequested(
    obj: WorkspaceBasicDeleteQuery
  ): Observable<WorkspaceBasicDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspaceBasicPut
  // PUT BODY START //
  public putOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceBasicPutResponse> {
    const obj = {
      id: values['id'],
      // TITLE UPDATE
      title: values['title'],
      // ADDITIONALPROPERTIES UPDATE
      additionalProperties: values['additionalProperties'],
      // CATEGORIES UPDATE
      categories: values['categories'],
      // LOGO UPDATE
      logo: values['logo'],
      // ENABLEDUNITS UPDATE
      enabledUnits: values['enabledUnits'],
      // SCHEMAACTIONS UPDATE
      schemaActions: values['schemaActions'],
      // APIKEY UPDATE
      apiKey: values['apiKey'],
      // ORDER UPDATE
      order: values['order'],
      // CREATEDAT UPDATE
      createdAt: values['createdAt'],
      // UPDATEDAT UPDATE
      updatedAt: values['updatedAt'],
      // DELETEDAT UPDATE
      deletedAt: values['deletedAt'],
      // PUT BODY FIELDS
    };
    return this.model.putOne(<any>obj);
  }
  // PUT BODY END //

  // workspaceBasicPost
  // POST BODY START //
  public postOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceBasicPostResponse> {
    const obj = {
      id: uuid.v4(),
      // TITLE CREATE
      title: values['title'],
      // ADDITIONALPROPERTIES CREATE
      additionalProperties: values['additionalProperties'],
      // CATEGORIES CREATE
      categories: values['categories'],
      // LOGO CREATE
      logo: values['logo'],
      // ENABLEDUNITS CREATE
      enabledUnits: values['enabledUnits'],
      // SCHEMAACTIONS CREATE
      schemaActions: values['schemaActions'],
      // APIKEY CREATE
      apiKey: values['apiKey'],
      // ORDER CREATE
      order: values['order'],
      // CREATEDAT CREATE
      createdAt: values['createdAt'],
      // UPDATEDAT CREATE
      updatedAt: values['updatedAt'],
      // DELETEDAT CREATE
      deletedAt: values['deletedAt'],
      // POST BODY FIELDS
    };
    return this.model.postOne(<any>obj);
  }
  // POST BODY END //

  // workspaceBasicGet
  public getOneRequested(obj: WorkspaceBasicGetQuery): {
    local: Workspace['basic'];
    promise: Observable<WorkspaceBasicGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
