<cb-button [type]="buttonType" (click)="onClickHandler()" [class.loading-status]="buttonStatus==='loading'"
  [class.error-status]="buttonStatus==='error'" [icon]="icon" [class.confirmed-status]="buttonStatus==='confirmed'"
  [disabled]="disabled">
  <ng-container *ngIf="buttonStatus==='normal'">
    <ng-content></ng-content>
  </ng-container>

  <ng-container *ngIf="progress != null && buttonStatus=='loading'">
    {{progress}}%
  </ng-container>
  <svg class="loading-icon" width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.8843 3.99434C11.9306 4.83753 12.6 6.12957 12.6 7.57795C12.6 10.1185 10.5405 12.178 8 12.178C5.45949 12.178 3.4 10.1185 3.4 7.57795C3.4 6.12961 4.06936 4.8376 5.11561 3.99441L4.12128 3.00007C2.8236 4.10066 2 5.74312 2 7.57795C2 10.8917 4.68629 13.578 8 13.578C11.3137 13.578 14 10.8917 14 7.57795C14 5.74308 13.1764 4.10058 11.8786 3L10.8843 3.99434Z"
      fill="currentColor" />
  </svg>

  <ion-icon class="error-icon" name="close"></ion-icon>
  <ion-icon class="checkmark-icon" name="checkmark"></ion-icon>
</cb-button>