//
// ───────────────────────────────────────────────── DEPENDENCIES AND COSTANT ─────
//
import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { getUrl, authApiFile } from '../classes/utils';
import { CbGlobalLoaderService } from '../widgets/cb-global-loader/cb-global-loader.service';

export enum FileManagerType {
    document = "document",
    image = "image",
    archive = "archive",
    video = "video",
    other = "other",
}

export enum FileType {
    large = "large",
    medium = "medium",
    small = "small",
    thumbnail = "thumbnail"
}

export interface FileManagerRoute {
    id: string;
    name: string;
}

export interface FileManagerFile {
    id: string;
    name: string;
    files: {
        type: FileType;
        url: string;
        size: number;
    }[];
    alt: { [key: string]: string };
    updatedAt?: Date;
    createdAt?: Date;
}

export interface FileManagerFolder {
    id: string;
    name: string;
    updatedAt?: Date;
    createdAt?: Date;
}

export interface FileManagerList {
    routeTree: FileManagerRoute[];
    fileGroups: FileManagerFile[];
    folders: FileManagerFolder[];
    availableActions?: {
        renameFolder?: boolean;
        deleteFolder?: boolean;
        uploadFile?: boolean;
        substituteFile?: boolean;
        deleteFile?: boolean;
        editFile?: boolean;
        downloadFile?: boolean;
    };
    allowedFormats?: string[];
}

const FILE_TYPES_DEFINITIONS: { [key: string]: { maxDimension: number, type: FileType } } = {
    "thumbnail": { maxDimension: 100, type: FileType.thumbnail },
    "small": { maxDimension: 400, type: FileType.small },
    "medium": { maxDimension: 800, type: FileType.medium },
    "large": { maxDimension: 1800, type: FileType.large },
};
@Injectable({
    providedIn: 'root',
})
export class UploaderService {
    static instance: UploaderService;
    public percentageSubject: Subject<number> = new Subject<number>();

    constructor(
        private readonly http: HttpClient,
        private readonly globalLoader: CbGlobalLoaderService
    ) {
        UploaderService.instance = this;
    }

    public fileUpload(file: File, workspace: string, token: string, id: string): Promise<{ url: string, resolution: { width: number, height: number } }[]> {
        let filesUrlUploaded: any = [];
        return new Promise(async (resolve, reject) => {
            if (file.type.includes('image')) {
                // Compress the image
                (await this.putFile(file, workspace, token, id)).subscribe((resp) => {
                    if (resp.type === HttpEventType.Response) {
                        filesUrlUploaded = resp.body;
                        this.globalLoader.hideLoader();
                        resolve(filesUrlUploaded);
                    } else if (resp.type === HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * resp.loaded / resp.total);
                        this.percentageSubject.next(percentDone);

                        if (percentDone < 100) {
                            this.globalLoader.showLoader(false, "FILES.UPLOADING_FILES_LOADING");
                            this.globalLoader.updatePercentage(percentDone);
                        } else {
                            this.globalLoader.showLoader(true, "FILES.COMPRESSING_FILES_LOADING");
                        }

                    }
                });
            }
            else resolve([])
        });
    }

    public async getImageData(file: File): Promise<{ width: number, height: number, ratio: number }> {
        return new Promise((resolve, reject) => {
            const URL = window.URL || window.webkitURL;
            const img = new Image();
            const objectUrl = URL.createObjectURL(file);
            img.onload = function () {
                const w: number = img.width;
                const h: number = img.height;
                URL.revokeObjectURL(objectUrl);
                resolve({
                    width: w,
                    height: h,
                    ratio: w / h
                });
            };
            img.onerror = function () {
                reject();
            }
            img.src = objectUrl;
        })
    }

    public getFileType(width: number, height: number): FileType {
        const maxDimension: number = Math.max(width, height);
        if (maxDimension >= FILE_TYPES_DEFINITIONS['large'].maxDimension) {
            return FileType.large;
        } else if (maxDimension >= FILE_TYPES_DEFINITIONS['medium'].maxDimension) {
            return FileType.medium;
        } else if (maxDimension >= FILE_TYPES_DEFINITIONS['small'].maxDimension) {
            return FileType.small;
        } else {
            return FileType.thumbnail;
        }
    }

    private getResolutionFromRatio(maxDimension: number, ratio: number): { width?: number, height?: number } {
        if (ratio > 1) {
            return {
                width: maxDimension,
                height: Math.round(maxDimension / ratio)
            }
        } else {
            return {
                width: Math.round(maxDimension * ratio),
                height: maxDimension
            }
        }
    }

    private getFileResolutionToGenerate(ratio: number, type: FileType): { width?: number, height?: number }[] {
        const toGenerate: { width?: number, height?: number }[] = [];
        switch (type) {
            case FileType.large:
                toGenerate.push(this.getResolutionFromRatio(FILE_TYPES_DEFINITIONS['large'].maxDimension, ratio));
                break;
            case FileType.medium:
                toGenerate.push(this.getResolutionFromRatio(FILE_TYPES_DEFINITIONS['medium'].maxDimension, ratio));
                break;
            case FileType.small:
                toGenerate.push(this.getResolutionFromRatio(FILE_TYPES_DEFINITIONS['small'].maxDimension, ratio));
                break;
            case FileType.thumbnail:
                toGenerate.push(this.getResolutionFromRatio(FILE_TYPES_DEFINITIONS['thumbnail'].maxDimension, ratio));
                break;
        }

        return toGenerate;
    }


    /**
         * upload file to google cloud storage
         */
    public putFileGoogle(fileToUpload: File, path: string): Observable<{ url: string, message: string }> {
        const fd = new FormData();
        fd.append('file', fileToUpload);
        fd.append('path', path);
        return this.http.post<any>(getUrl('/upload'), fd);
    }

    /**
     * Put the file into bunny cdn
     */
    public async putFile(fileToUpload: File, workspace: string, token: string, id: string): Promise<Observable<any>> {
        const fileName: string = fileToUpload.name;
        const imageData: { width: number, height: number, ratio: number } = await this.getImageData(fileToUpload);
        const fileType: FileType = this.getFileType(imageData.width, imageData.height);
        const resolutions: { width?: number, height?: number }[] = this.getFileResolutionToGenerate(imageData.ratio, fileType);

        const exports = [];
        for (const resolution of resolutions) {
            exports.push({ width: resolution.width, height: resolution.height, status: "downloaded", formats: ["jpg", "webp"], quality: 70 });
        }

        const formData: FormData = new FormData();
        formData.append("image", fileToUpload);
        formData.append("path", "webmanager/" + workspace);
        formData.append("filename", fileName);
        formData.append("id", id);
        formData.append("data", JSON.stringify({ id: id }));
        formData.append("exports", JSON.stringify(exports));
        return this.http.post<any>(getUrl('/imageoptimizer'), formData, authApiFile(true, token, workspace));
    }

}