import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CbStandardForm } from '../../cb-form/cb-form-interfaces';
import { CbFormComponent } from '../../cb-form/cb-form/cb-form.component';

@Component({
  selector: 'app-cb-dialog-form',
  templateUrl: './cb-dialog-form.component.html',
  styleUrls: ['./cb-dialog-form.component.scss'],
})
export class CbDialogFormComponent {
  public width!: string;

  public title = '';
  public form!: CbStandardForm;
  public data?: any;

  @ViewChild(CbFormComponent) public formComponent!: CbFormComponent;

  constructor(
    private readonly dialogRef: MatDialogRef<CbDialogFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: { form: CbStandardForm; data?: any; width: string }
  ) {
    if (dialogData) {
      this.width = dialogData.width;
      this.title = dialogData.form.title;
      this.form = dialogData.form;
      this.data = dialogData.data;
    } else {
      this.closeDialog();
    }

  }

  public get hasErrors(): boolean {
    return this.formComponent.hasErrors;
  }

  public submitClick(data: any): void {
    if (this.formComponent.values) {
      this.form.submitButton?.onClick?.(this.formComponent.values);
      this.closeDialog(this.formComponent.values);
    }
  }
  public deleteClick(data: any): void {
    this.form.deleteButton?.onClick?.(this.formComponent.values);
    this.closeDialog(this.formComponent.values);
  }

  public closeDialog(value?: any): void {
    this.dialogRef.close(value);
  }
}
