import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataManager } from '../classes/dataManager';
import { LifecyclesManager } from '../classes/lifecyclesManager';
import { BasicPresenter } from '../classes/presenters/basicPresenter';
import { UserPresenter } from '../classes/presenters/userPresenter';
import { UserManager } from '../classes/userManager';
import { CbGlobalLoaderService } from '../widgets/cb-global-loader/cb-global-loader.service';
import { CbToastService } from '../widgets/cb-toast/cb-toast.service';
import { HttpService } from './http.service';
import { UploaderService } from './uploader.service';

@Injectable()
export class InitializerService {
  public userPresenter: UserPresenter;
  public basicPresenter: BasicPresenter;

  constructor(private readonly http: HttpClient,
  ) {
    /// CONTENT
    new HttpService(http);
    new CbToastService();
    new UploaderService(http, new CbGlobalLoaderService());
    new DataManager();
    new UserManager();
    new LifecyclesManager();
    this.userPresenter = new UserPresenter();
    this.basicPresenter = new BasicPresenter();
  }

  load() {
    return new Promise((resolve, reject) => {

      // Check if accessToken is expired
      if (this.userPresenter.isTokenExpired()) {
        return this.userPresenter.tokenRequested({ refreshToken: this.userPresenter.getRefreshToken() }).toPromise().then((data) => {
          if (this.basicPresenter.getSelectedRequested()) {
            DataManager.getInstance().loadBasic(true).then((data) => {
              return resolve(true);
            }).catch((err) => {
              console.error(err);
              return resolve(true);
            });
          } else {
            return resolve(true);
          }
        }).catch((err) => {
          if (this.basicPresenter.getSelectedRequested()) {
            DataManager.getInstance().loadBasic(true).then((data) => {
              return resolve(true);
            }).catch((err) => {
              console.error(err);
              return resolve(true);
            });
          } else {
            return resolve(true);
          }
        }
        );
      } else {
        if (this.basicPresenter.getSelectedRequested()) {
          DataManager.getInstance().loadBasic(true).then((data) => {
            return resolve(true);
          }).catch((err) => {
            console.error(err);
            return resolve(true);
          });
        } else {
          return resolve(true);
        }

      }

    });
  }
}
