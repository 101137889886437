/// IMPORTS
import {
  WorkspaceLifecycleGetQuery,
  WorkspaceLifecycleGetResponse,
  WorkspaceLifecyclePostBody,
  WorkspaceLifecyclePostResponse,
  WorkspaceLifecyclePutBody,
  WorkspaceLifecyclePutResponse,
  WorkspaceLifecycleDeleteQuery,
  WorkspaceLifecycleDeleteResponse,
  WorkspaceLifecyclesGetQuery,
  WorkspaceLifecyclesGetResponse,
  WorkspaceLifecyclePostsBody,
  WorkspaceLifecyclePostsResponse,
  WorkspaceLifecyclePutsBody,
  WorkspaceLifecyclePutsResponse,
  WorkspaceLifecycleDeletesQuery,
  WorkspaceLifecycleDeletesResponse,
  WorkspaceLifecycleOrderPutBody,
  WorkspaceLifecycleOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class LifecyclesOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceLifecycleOrderPut
  public changeOrder(
    obj: WorkspaceLifecycleOrderPutBody
  ): Observable<WorkspaceLifecycleOrderPutResponse> {
    return this.http.put<WorkspaceLifecycleOrderPutResponse>(
      getUrl('/workspace/lifecycles/order'),
      obj,
      authApi(true)
    );
  }

  // workspaceLifecycleDeletes
  public deletesMany(
    obj: WorkspaceLifecycleDeletesQuery
  ): Observable<WorkspaceLifecycleDeletesResponse> {
    return this.http.delete<WorkspaceLifecycleDeletesResponse>(
      getUrl('/workspace/lifecycles') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceLifecyclePuts
  public putsMany(
    obj: WorkspaceLifecyclePutsBody
  ): Observable<WorkspaceLifecyclePutsResponse> {
    return this.http.put<WorkspaceLifecyclePutsResponse>(
      getUrl('/workspace/lifecycles'),
      obj,
      authApi(true)
    );
  }

  // workspaceLifecyclePosts
  public postsMany(
    obj: WorkspaceLifecyclePostsBody
  ): Observable<WorkspaceLifecyclePostsResponse> {
    return this.http.post<WorkspaceLifecyclePostsResponse>(
      getUrl('/workspace/lifecycles'),
      obj,
      authApi(true)
    );
  }

  // workspaceLifecyclesGet
  public getMany(
    obj: WorkspaceLifecyclesGetQuery
  ): Observable<WorkspaceLifecyclesGetResponse> {
    return this.http.get<WorkspaceLifecyclesGetResponse>(
      getUrl('/workspace/lifecycles') + objectToQueryString(obj),
      authApi(true)
    );
  }

  // workspaceLifecycleDelete
  public deleteOne(
    obj: WorkspaceLifecycleDeleteQuery
  ): Observable<WorkspaceLifecycleDeleteResponse> {
    return this.http.delete<WorkspaceLifecycleDeleteResponse>(
      getUrl('/workspace/lifecycle') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceLifecyclePut
  public putOne(
    obj: WorkspaceLifecyclePutBody
  ): Observable<WorkspaceLifecyclePutResponse> {
    return this.http.put<WorkspaceLifecyclePutResponse>(
      getUrl('/workspace/lifecycle'),
      obj,
      authApi(true)
    );
  }

  // workspaceLifecyclePost
  public postOne(
    obj: WorkspaceLifecyclePostBody
  ): Observable<WorkspaceLifecyclePostResponse> {
    return this.http.post<WorkspaceLifecyclePostResponse>(
      getUrl('/workspace/lifecycle'),
      obj,
      authApi(true)
    );
  }

  // workspaceLifecycleGet
  public getOne(
    obj: WorkspaceLifecycleGetQuery
  ): Observable<WorkspaceLifecycleGetResponse> {
    return this.http.get<WorkspaceLifecycleGetResponse>(
      getUrl('/workspace/lifecycle') + objectToQueryString(obj),
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
