<cb-dialog [closeButton]="false" [width]="'400px'">
    <ng-container Title>
        <div class="cb-dialog-entity-title">
            <h4>{{"ENTITY.CLOSING_LIFECYCLE" | translate}}</h4>
            <div class="cb-dialog-info">
                <p>{{unit?.name}}</p>
                <p>{{lifecycle?.identifier}}</p>
            </div>
        </div>
    </ng-container>

    <ng-container Content>
        <div class="cb-confirm-close-message">
            <p
                [innerHTML]="'ENTITY.CLOSING_LIFECYCLE_MESSAGE_1' | translate:{ lifecycle: lifecycle?.identifier, entity: unit?.name}">
            </p>
            <p [innerHTML]="'ENTITY.CLOSING_LIFECYCLE_MESSAGE_2' | translate"></p>
        </div>
    </ng-container>

    <ng-container LeftButtons>
        <cb-button (click)="cancel()" type="primary">{{"COMMON.CANCEL" | translate}}</cb-button>
    </ng-container>

    <ng-container RightButtons>
        <cb-button-loading type="neutral" (submit)="submit($event)">{{"COMMON.CONFIRM" |
            translate}}</cb-button-loading>
    </ng-container>
</cb-dialog>