import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { DataManager } from 'src/app/classes/dataManager';

@Component({
  selector: 'cb-user',
  templateUrl: './cb-user.component.html',
  styleUrls: ['./cb-user.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CbUserComponent implements OnInit, OnChanges {
  @Input() public shape: 'circle' | 'square' = 'circle';
  @Input() public picture?: string;
  @Input() public size: 'small' | 'medium' | 'large' | 'huge' = 'medium';
  @Input() public username: string | null = '';
  @Input() public hasNotifications = false;

  constructor(private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.cdr.detectChanges();
    }
  }

  public get hasProfilePicture(): boolean {
    return this.picture !== undefined && this.picture.length > 0;
  }

  public get initials(): string {
    if (this.username) {
      return this.username
        .split(' ')
        .map((word) => word[0])
        .join('')
        .slice(0, 2);
    }

    return '';
  }

  public get workspaceName(): string{
    return DataManager.getInstance().title;
  }
}
