import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManager } from 'src/app/classes/dataManager';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { CbToastService } from 'src/app/widgets/cb-toast/cb-toast.service';
import { PhaseType } from '../../phases.module';
import { CbCustomField, CbCustomFieldType } from '../../widgets/fields-customization-widget/fields-customization-widget.component';
import * as uuid from 'uuid';
import { UnitsManager } from 'src/app/classes/unitsManager';
import { TranslateService } from '@ngx-translate/core';
import { CbGlobalLoaderService } from 'src/app/widgets/cb-global-loader/cb-global-loader.service';

@Component({
  selector: 'app-create-edit-entity-dialog',
  templateUrl: './create-edit-entity-dialog.component.html',
  styleUrls: ['./create-edit-entity-dialog.component.scss']
})
export class CreateEditEntityDialogComponent implements OnInit {

  public search: string = '';
  public isEditing: boolean = false;

  public fields: CbCustomField[] = [];

  public entity: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entity: any, type: PhaseType },
    private readonly dialogRef: MatDialogRef<CreateEditEntityDialogComponent>,
    private readonly globalLoaderService: CbGlobalLoaderService,
    private readonly translate: TranslateService
  ) {
    this.isEditing = (data && data.entity);
  }

  ngOnInit(): void {
    DataManager.getInstance().fromPhasePropertiesToFields(this.data.type).then(fields => {
      this.fields = fields;
      this.loadEntity();
    });
  }

  private loadEntity(): void {
    const data = { ...this.data.entity }
    this.entity = {
      id: data.id ?? uuid.v4(),
      assetId: data.assetId,
      name: data.name ?? '',
      identifier: data.identifier ?? '',
      properties: {}
    }

    for (let property of this.fields) {
      const properties = data?.properties ?? {};
      const prop = (properties)[property.name];
      const value = prop;
      this.entity.properties[property.name] = value;
    }

  }

  private loadEntityForApi(): any {
    const data = { ...this.entity }
    const type = this.data.type ?? 'vineyards'
    const isSingle = type === 'lots';
    return {
      id: data.id,
      assetId: data.assetId,
      name: data.name,
      identifier: data.identifier,
      properties: this.fields.map(field => ({ name: field.name, value: data.properties[field.name] })),
      unitType: type,
      isSingle: isSingle
    }
  }

  public async submit(event: CbLoadingButtonEvent): Promise<void> {
    try {
      
      this.globalLoaderService.showLoader(
        true,
        (this.isEditing)? this.translate.instant("COMMON.UPDATING_BLOCKCHAIN") : this.translate.instant("COMMON.SAVING_BLOCKCHAIN"),
      );

      const body = this.loadEntityForApi();

      await UnitsManager.getInstance().updateOrInsertUnit(body);

      event.success.next();
      setTimeout(() => {
        this.globalLoaderService.hideLoader();
        this.dialogRef.close({ id: body.id });
      }, 200);
    } catch (error) {
      this.globalLoaderService.hideLoader();
      event.error.next();
      CbToastService.instance.showHttpError(error);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }

  public getFieldOptions(field: CbCustomField): { label: string, value: string }[] {
    return field.options?.map(option => ({ label: option, value: option })) ?? [];
  }

  public readonly fieldTypes: typeof CbCustomFieldType = CbCustomFieldType;
}
