import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { SettingsPopoverItem } from '../../cb-popover/cb-settings-popover/cb-settings-popover.component';
import { UserManager } from 'src/app/classes/userManager';

@Component({
  selector: 'cb-topbar-user',
  templateUrl: './cb-topbar-user.component.html',
  styleUrls: ['./cb-topbar-user.component.scss'],
})
export class CbTopbarUserComponent implements OnInit {
  @Input() public username = '';
  @Input() public picture = '/assets/images/default-picture.svg';

  @Output('changePassword') public onChangePassword: EventEmitter<void> =
    new EventEmitter<void>();
  @Output('logout') public onLogout: EventEmitter<void> =
    new EventEmitter<void>();

  public readonly adminPopoverOptions: SettingsPopoverItem[] = [
    { icon: 'change', name: 'changeworkspace', label: 'COMMON.CHANGECLIENT', type: 'default' },
    { icon: 'password', name: 'password', label: 'COMMON.CHANGEPASSWORD', type: 'default' },
    { icon: 'logout', name: 'logout', label: 'COMMON.LOGOUT', type: 'danger' },
  ];
  
  public readonly popoverOptions: SettingsPopoverItem[] = [
    { icon: 'password', name: 'password', label: 'COMMON.CHANGEPASSWORD', type: 'default' },
    { icon: 'logout', name: 'logout', label: 'COMMON.LOGOUT', type: 'danger' },
  ];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  public get isAdmin(): boolean {
    return UserManager.getInstance().isAdmin;
  }

  public changePassword(): void {
    this.onChangePassword.emit();
  }

  public changeWorkspace(): void {
    this.router.navigate(['/auth/select-workspace']);
  }

  public optionSelected(option: string): void {
    switch (option) {
      case 'password':
        this.changePassword();
        break;
      case 'logout':
        this.logout();
        break;
      case 'changeworkspace':
        this.changeWorkspace();
        break;
    }
  }

  public logout(): void {
    this.onLogout.emit();
  }
}
