import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'cb-icon-button',
  templateUrl: './cb-icon-button.component.html',
  styleUrls: ['./cb-icon-button.component.scss'],
})
export class CbIconButtonComponent implements OnInit {
  public formLoading = false;
  public formSuccess = false;
  public formError = false;

  @Input() public type:
    | 'dark'
    | 'primary'
    | 'accent'
    | 'warning'
    | 'danger'
    | 'success'
    | 'info'
    | 'clear'
    | 'neutral' = 'neutral';
  @Input() public icon!: string;
  @Input() public disabled = false;

  @Input() public large: boolean = false;

  @Output() private click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  constructor() { }

  ngOnInit(): void { }

  public onClick(event: MouseEvent): void {
    if (this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    } else {
      this.click.emit(event);
    }
  }

  public setLoading(): void {
    this.formLoading = true;
  }

  public setSuccess(): void {
    this.type = 'success';
    this.formLoading = false;
    this.formSuccess = true;
  }

  public setError(): void {
    const oldType = this.type;
    this.type = 'danger';
    this.formError = true;

    setTimeout(() => {
      this.type = oldType;
      this.formError = false;
    });
  }
}
