<div class="cb-page-content">
    <div class="cb-page-header">
        <cb-topbar>
            <div class="cb-phase-header">
                <cb-icon [name]="pageIcon"></cb-icon>
                <h3>{{pageTitle | translate}}</h3>
            </div>
        </cb-topbar>
    </div>
    <div class="cb-page">
        <div class="cb-page-actions">
            <div class="cb-page-filters">
                <cb-search [placeholder]="'COMMON.SEARCH' | translate" [(ngModel)]="search"
                    (input)="searchNow()"></cb-search>
            </div>
            <div class="cb-page-buttons">
                <ng-container *ngIf="isAdmin">
                    <cb-button icon="edit" (click)="editEntityActions()">{{"PHASE.EDIT_ACTIONS" |
                        translate}}</cb-button>
                    <cb-button icon="edit" (click)="editEntityProperties()">{{"PHASE.EDIT_FIELDS" |
                        translate}}</cb-button>
                </ng-container>
                <cb-button (click)="createNewEntity()" type="primary" icon="add-circle">{{"COMMON.CREATE" |
                    translate}}</cb-button>
            </div>
        </div>
        <div class="cb-page-table">
            <ng-container *ngIf="!loading">
                <p-table [value]="entities" (sortFunction)="tableSort($event)" [customSort]="true"
                    [paginator]="entities && entities.length>entitiesPerPage" [rows]="entitiesPerPage"
                    [showCurrentPageReport]="false" paginatorDropdownAppendTo="body"
                    [currentPageReportTemplate]="'COMMON.TABLE_PAGE_REPORT' | translate">
                    <ng-template pTemplate="header">
                        <tr>
                            <th pSortableColumn="identifier">
                                {{"ENTITY.FIELDS.IDENTIFIER" | translate}}
                                <p-sortIcon field="identifier"></p-sortIcon>
                            </th>
                            <th pSortableColumn="name">
                                {{"ENTITY.FIELDS.NAME" | translate}}
                                <p-sortIcon field="name"></p-sortIcon>
                            </th>
                            <ng-container *ngFor="let field of fields">
                                <ng-container *ngIf="field.isActive">
                                    <th pSortableColumn="properties.{{field.name}}">
                                        {{field.label}}
                                        <p-sortIcon field="properties.{{field.name}}">
                                        </p-sortIcon>
                                    </th>
                                </ng-container>
                            </ng-container>
                            <th alignFrozen="right" pFrozenColumn></th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-entity>
                        <tr>
                            <td>{{entity.identifier}}</td>
                            <td>{{entity.name}}</td>
                            <ng-container *ngFor="let field of fields">
                                <ng-container *ngIf="field.isActive">
                                    <td>{{entity.properties[field.name]}}</td>
                                </ng-container>
                            </ng-container>
                            <td alignFrozen="right" pFrozenColumn>
                                <div class="cb-table-buttons">
                                    <cb-icon-button *ngIf="entity.isSingle" (click)="RowSettingsPanel.toggle($event)" type="clear"
                                        icon="ellipsis-horizontal"></cb-icon-button>
                                    <cb-icon-button (click)="editEntity(entity)" type="neutral"
                                        icon="edit"></cb-icon-button>
                                    <cb-button [routerLink]="entity.id+'/lifecycles'" type="accent" [tableButton]="true" iconPos="right"
                                        icon="chevron-forward">{{"PHASE.LIFECYCLES" | translate}}</cb-button>
                                </div>
                                <p-overlayPanel #RowSettingsPanel>
                                    <cb-settings-popover [settingsList]="tableRowSettings"
                                        (settingClicked)="settingClicked($event, entity)"></cb-settings-popover>
                                </p-overlayPanel>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </ng-container>
        </div>
    </div>
</div>