/// IMPORTS
import {
  WorkspaceUnitGetQuery,
  WorkspaceUnitGetResponse,
  WorkspaceUnitPostBody,
  WorkspaceUnitPostResponse,
  WorkspaceUnitPutBody,
  WorkspaceUnitPutResponse,
  WorkspaceUnitDeleteQuery,
  WorkspaceUnitDeleteResponse,
  WorkspaceUnitsGetQuery,
  WorkspaceUnitsGetResponse,
  WorkspaceUnitPostsBody,
  WorkspaceUnitPostsResponse,
  WorkspaceUnitPutsBody,
  WorkspaceUnitPutsResponse,
  WorkspaceUnitDeletesQuery,
  WorkspaceUnitDeletesResponse,
  WorkspaceUnitOrderPutBody,
  WorkspaceUnitOrderPutResponse,
} from 'generatedInterfaces';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { getUrl, authApi, deleteAuthApi, objectToQueryString } from '../utils';
import { HttpService } from 'src/app/services/http.service';

export class UnitsOnlineEndpoint {
  /// PROPERTIES
  private http: HttpClient = HttpService.instance.http;

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceUnitOrderPut
  public changeOrder(
    obj: WorkspaceUnitOrderPutBody
  ): Observable<WorkspaceUnitOrderPutResponse> {
    return this.http.put<WorkspaceUnitOrderPutResponse>(
      getUrl('/workspace/units/order'),
      obj,
      authApi(true)
    );
  }

  // workspaceUnitDeletes
  public deletesMany(
    obj: WorkspaceUnitDeletesQuery
  ): Observable<WorkspaceUnitDeletesResponse> {
    return this.http.delete<WorkspaceUnitDeletesResponse>(
      getUrl('/workspace/units') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceUnitPuts
  public putsMany(
    obj: WorkspaceUnitPutsBody
  ): Observable<WorkspaceUnitPutsResponse> {
    return this.http.put<WorkspaceUnitPutsResponse>(
      getUrl('/workspace/units'),
      obj,
      authApi(true)
    );
  }

  // workspaceUnitPosts
  public postsMany(
    obj: WorkspaceUnitPostsBody
  ): Observable<WorkspaceUnitPostsResponse> {
    return this.http.post<WorkspaceUnitPostsResponse>(
      getUrl('/workspace/units'),
      obj,
      authApi(true)
    );
  }

  // workspaceUnitsGet
  public getMany(
    obj: WorkspaceUnitsGetQuery
  ): Observable<WorkspaceUnitsGetResponse> {
    return this.http.get<WorkspaceUnitsGetResponse>(
      getUrl('/workspace/units') + objectToQueryString(obj),
      authApi(true)
    );
  }

  // workspaceUnitDelete
  public deleteOne(
    obj: WorkspaceUnitDeleteQuery
  ): Observable<WorkspaceUnitDeleteResponse> {
    return this.http.delete<WorkspaceUnitDeleteResponse>(
      getUrl('/workspace/unit') + objectToQueryString(obj),
      deleteAuthApi(true, obj)
    );
  }

  // workspaceUnitPut
  public putOne(
    obj: WorkspaceUnitPutBody
  ): Observable<WorkspaceUnitPutResponse> {
    return this.http.put<WorkspaceUnitPutResponse>(
      getUrl('/workspace/unit'),
      obj,
      authApi(true)
    );
  }

  // workspaceUnitPost
  public postOne(
    obj: WorkspaceUnitPostBody
  ): Observable<WorkspaceUnitPostResponse> {
    return this.http.post<WorkspaceUnitPostResponse>(
      getUrl('/workspace/unit'),
      obj,
      authApi(true)
    );
  }

  // workspaceUnitGet
  public getOne(
    obj: WorkspaceUnitGetQuery
  ): Observable<WorkspaceUnitGetResponse> {
    return this.http.get<WorkspaceUnitGetResponse>(
      getUrl('/workspace/unit') + objectToQueryString(obj),
      authApi(true)
    );
  }

  /// CUSTOM METHODS

  ///
}
