<div class="cb-sidebar-container">
  <div class="cb-sidebar-header" routerLink="home">
    <img src="assets/logo/logo-plain.svg"/>
  </div>

  <div class="cb-sidebar-top-routes">
    <ul>
      <li routerLink="home" class="cb-home-route" [class.selected]="isRouteActive('home')">
        <cb-icon name="house"></cb-icon>
        <p>{{ "SIDEBAR.HOME" | translate }}</p>
      </li>
      <li *ngIf="isAdmin" routerLink="users" [class.selected]="isRouteActive('users')">
        <cb-icon name="users"></cb-icon>
        <p>{{ "SIDEBAR.USERS" | translate }}</p>
      </li>
    </ul>
  </div>

  <div class="cb-sidebar-routes">
    <ul>
      <ng-container *ngFor="let p of phases">
        <li [routerLink]="'tracking/'+p.type" [class.selected]="isRouteActive(p.type)">
          <cb-icon [name]="p.icon"></cb-icon>
          <p>{{ p.name | translate }}</p>
        </li>
      </ng-container>
    </ul>
  </div>
</div>