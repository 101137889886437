import { lastValueFrom } from "rxjs";
import { PhaseType } from "../modules/phases/phases.module";
import { UnitsPresenter } from "./presenters/unitsPresenter";
import { UserPresenter } from "./presenters/userPresenter";
import { UsersPresenter } from "./presenters/usersPresenter";
import { User } from "./user";
import { Workspace, WorkspaceBasicAdditionalPropertie, WorkspaceBasicSchemaAction } from "./workspace";

export class UnitsManager {

    // Singleton for data manager
    private static instance: UnitsManager;
    private unitsPresenter = new UnitsPresenter();

    public units: Workspace["units"] | undefined = undefined;

    public unitsFromType(type: PhaseType): Workspace["units"] {
        return this.units?.filter((unit) => unit.unitType === type) ?? [];
    }

    public static getInstance(): UnitsManager {
        if (!UnitsManager.instance) {
            UnitsManager.instance = new UnitsManager();
        }
        return UnitsManager.instance;
    }

    public async loadUnits(force = false): Promise<void> {
        if (!this.units || force) {
            const result = await lastValueFrom(this.unitsPresenter.getManyRequested({}).promise);
            this.units = result.main as Workspace["units"];
            console.log(this.units);
        }
    }

    public async canDeleteType(type: PhaseType): Promise<boolean> {
        await this.loadUnits();
        const units = this.unitsFromType(type);
        return units.length === 0;
    }

    public async updateOrInsertUnit(unit: Workspace["units"][0]): Promise<void> {
        await this.loadUnits();
        const found = this.units?.find((u) => u.id === unit.id);
        console.log(unit);
        if (found) {
            await lastValueFrom(this.unitsPresenter.putOneRequested(unit));
        } else {
            await lastValueFrom(this.unitsPresenter.postOneRequested(unit));
        }
        await this.loadUnits(true);
    }

    public async getUnitById(id: string): Promise<Workspace["units"][0]> {
        await this.loadUnits();
        const unit = this.units?.find((u) => u.id === id);
        if (!unit) {
            throw new Error("Unit not found");
        }
        return unit;
    }

}