<cb-dialog [width]="'400px'" [closeButton]="false">
    <ng-container Title>
        <div class="cb-dialog-entity-title">
            <h4 *ngIf="data.title">{{data.title}}</h4>
            <div *ngIf="data.subtitle" class="cb-dialog-info">
                <p>{{data.subtitle}}</p>
            </div>
        </div>
    </ng-container>

    <ng-container Content>
        <div class="cb-qrcode-container">
            <qrcode (qrCodeURL)="onChangeURL($event)" [elementType]="'img'" [qrdata]="data.qr" [width]="1024" [errorCorrectionLevel]="'M'" [margin]="2"></qrcode>
            <div class="cb-qrcode-buttons">
                <a [href]="qrCodeDownloadLink" download="qrcode">
                    <cb-button type="primary" icon="download">{{"COMMON.DOWNLOAD" | translate}}</cb-button>
                </a>
                <cb-button (click)="close()">{{"COMMON.CLOSE" | translate}}</cb-button>
            </div>
        </div>
    </ng-container>
</cb-dialog>