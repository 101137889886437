/// IMPORTS
import {
  WorkspaceLifecycleGetQuery,
  WorkspaceLifecycleGetResponse,
  WorkspaceLifecyclePostBody,
  WorkspaceLifecyclePostResponse,
  WorkspaceLifecyclePutBody,
  WorkspaceLifecyclePutResponse,
  WorkspaceLifecycleDeleteQuery,
  WorkspaceLifecycleDeleteResponse,
  WorkspaceLifecyclesGetQuery,
  WorkspaceLifecyclesGetResponse,
  WorkspaceLifecyclePostsBody,
  WorkspaceLifecyclePostsResponse,
  WorkspaceLifecyclePutsBody,
  WorkspaceLifecyclePutsResponse,
  WorkspaceLifecycleDeletesQuery,
  WorkspaceLifecycleDeletesResponse,
  WorkspaceLifecycleOrderPutBody,
  WorkspaceLifecycleOrderPutResponse,
} from 'generatedInterfaces';
import { LifecyclesModel } from 'src/app/classes/models/lifecyclesModel';
import { Workspace } from '../workspace';
import * as uuid from 'uuid';
import { Observable } from 'rxjs/internal/Observable';

export class LifecyclesPresenter {
  /// PROPERTIES
  private model: LifecyclesModel = new LifecyclesModel();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceLifecycleOrderPut
  public changeOrderRequested(
    obj: WorkspaceLifecycleOrderPutBody
  ): Observable<WorkspaceLifecycleOrderPutResponse> {
    return this.model.changeOrder(obj);
  }

  // workspaceLifecycleDeletes
  public deletesManyRequested(
    obj: WorkspaceLifecycleDeletesQuery
  ): Observable<WorkspaceLifecycleDeletesResponse> {
    return this.model.deletesMany(obj);
  }

  // workspaceLifecyclePuts
  public putsManyRequested(
    obj: WorkspaceLifecyclePutsBody
  ): Observable<WorkspaceLifecyclePutsResponse> {
    return this.model.putsMany(obj);
  }

  // workspaceLifecyclePosts
  public postsManyRequested(
    obj: WorkspaceLifecyclePostsBody
  ): Observable<WorkspaceLifecyclePostsResponse> {
    return this.model.postsMany(obj);
  }

  // workspaceLifecyclesGet
  public getManyRequested(obj: WorkspaceLifecyclesGetQuery): {
    local: Workspace['lifecycles'];
    promise: Observable<WorkspaceLifecyclesGetResponse>;
  } {
    return this.model.getMany(obj);
  }

  // workspaceLifecycleDelete
  public deleteOneRequested(
    obj: WorkspaceLifecycleDeleteQuery
  ): Observable<WorkspaceLifecycleDeleteResponse> {
    return this.model.deleteOne(obj);
  }

  // workspaceLifecyclePut
  // PUT BODY START //
  public putOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceLifecyclePutResponse> {
    const obj = {
      id: values['id'],
      // ASSETID UPDATE
      assetId: values['assetId'],
      // IDENTIFIER UPDATE
      identifier: values['identifier'],
      // STATUS UPDATE
      status: values['status'],
      // UNITID UPDATE
      unitId: values['unitId'],
      // DEPENDENCYIDS UPDATE
      dependencyIds: values['dependencyIds'],
      // ACTIONS UPDATE
      actions: values['actions'],
      // ORDER UPDATE
      order: values['order'],
      // CREATEDAT UPDATE
      createdAt: values['createdAt'],
      // UPDATEDAT UPDATE
      updatedAt: values['updatedAt'],
      // DELETEDAT UPDATE
      deletedAt: values['deletedAt'],
      // PUT BODY FIELDS
    };
    console.log('putOneRequested', obj);
    return this.model.putOne(<any>obj);
  }
  // PUT BODY END //

  // workspaceLifecyclePost
  // POST BODY START //
  public postOneRequested(values: {
    [key: string]: any;
  }): Observable<WorkspaceLifecyclePostResponse> {
    const obj = {
      id: uuid.v4(),
      // ASSETID CREATE
      assetId: values['assetId'],
      // IDENTIFIER CREATE
      identifier: values['identifier'],
      // STATUS CREATE
      status: values['status'],
      // UNITID CREATE
      unitId: values['unitId'],
      // DEPENDENCYIDS CREATE
      dependencyIds: values['dependencyIds'],
      // ACTIONS CREATE
      actions: values['actions'],
      // ORDER CREATE
      order: values['order'],
      // CREATEDAT CREATE
      createdAt: values['createdAt'],
      // UPDATEDAT CREATE
      updatedAt: values['updatedAt'],
      // DELETEDAT CREATE
      deletedAt: values['deletedAt'],
      // POST BODY FIELDS
    };
    return this.model.postOne(<any>obj);
  }
  // POST BODY END //

  // workspaceLifecycleGet
  public getOneRequested(obj: WorkspaceLifecycleGetQuery): {
    local: Workspace['lifecycles'][0];
    promise: Observable<WorkspaceLifecycleGetResponse>;
  } {
    return this.model.getOne(obj);
  }

  /// CUSTOM METHODS

  ///
}
