/// IMPORTS
import {
  WorkspaceUnitGetQuery,
  WorkspaceUnitGetResponse,
  WorkspaceUnitPostBody,
  WorkspaceUnitPostResponse,
  WorkspaceUnitPutBody,
  WorkspaceUnitPutResponse,
  WorkspaceUnitDeleteQuery,
  WorkspaceUnitDeleteResponse,
  WorkspaceUnitsGetQuery,
  WorkspaceUnitsGetResponse,
  WorkspaceUnitPostsBody,
  WorkspaceUnitPostsResponse,
  WorkspaceUnitPutsBody,
  WorkspaceUnitPutsResponse,
  WorkspaceUnitDeletesQuery,
  WorkspaceUnitDeletesResponse,
  WorkspaceUnitOrderPutBody,
  WorkspaceUnitOrderPutResponse,
} from 'generatedInterfaces';
import { UnitsOfflineEndpoint } from 'src/app/classes/offlineEndpoints/unitsOfflineEndpoint';
import { UnitsOnlineEndpoint } from 'src/app/classes/onlineEndpoints/unitsOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../workspace';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class UnitsModel {
  /// PROPERTIES
  private onlineEndpoint: UnitsOnlineEndpoint = new UnitsOnlineEndpoint();
  private offlineEndpoint: UnitsOfflineEndpoint = new UnitsOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceUnitOrderPut
  public changeOrder(
    obj: WorkspaceUnitOrderPutBody
  ): Observable<WorkspaceUnitOrderPutResponse> {
    return this.onlineEndpoint.changeOrder(obj).pipe(
      map(
        (response: WorkspaceUnitOrderPutResponse) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUnitDeletes
  public deletesMany(
    obj: WorkspaceUnitDeletesQuery
  ): Observable<WorkspaceUnitDeletesResponse> {
    return this.onlineEndpoint.deletesMany(obj).pipe(
      map(
        (response: WorkspaceUnitDeletesResponse) => {
          const objs = obj.ids.map((val) => {
            return { id: val };
          });
          for (let obj of objs)
            this.offlineEndpoint.delete(obj as Workspace['units'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUnitPuts
  public putsMany(
    obj: WorkspaceUnitPutsBody
  ): Observable<WorkspaceUnitPutsResponse> {
    return this.onlineEndpoint.putsMany(obj).pipe(
      map(
        (response: WorkspaceUnitPutsResponse) => {
          const objs = obj.bodies;
          for (let obj of objs)
            this.offlineEndpoint.pushOrUpdate(obj as Workspace['units'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUnitPosts
  public postsMany(
    obj: WorkspaceUnitPostsBody
  ): Observable<WorkspaceUnitPostsResponse> {
    return this.onlineEndpoint.postsMany(obj).pipe(
      map(
        (response: WorkspaceUnitPostsResponse) => {
          const objs = obj.bodies;
          for (let obj of objs)
            this.offlineEndpoint.pushOrUpdate(obj as Workspace['units'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUnitsGet
  public getMany(obj: WorkspaceUnitsGetQuery): {
    local: Workspace['units'];
    promise: Observable<WorkspaceUnitsGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getMany(obj).filter((item) => {
        for (let key of Object.keys(obj)) {
          if ((<any>item)[key] != (<any>obj)[key]) {
            return false;
          }
        }
        return true;
      }),
      promise: this.onlineEndpoint.getMany(obj).pipe(
        map(
          (response: WorkspaceUnitsGetResponse) => {
            let mains = response.main;
            if (mains)
              this.offlineEndpoint.setEntities(mains as Workspace['units']);

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  // workspaceUnitDelete
  public deleteOne(
    obj: WorkspaceUnitDeleteQuery
  ): Observable<WorkspaceUnitDeleteResponse> {
    return this.onlineEndpoint.deleteOne(obj).pipe(
      map(
        (response: WorkspaceUnitDeleteResponse) => {
          this.offlineEndpoint.delete(obj as Workspace['units'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUnitPut
  public putOne(
    obj: WorkspaceUnitPutBody
  ): Observable<WorkspaceUnitPutResponse> {
    return this.onlineEndpoint.putOne(obj).pipe(
      map(
        (response: WorkspaceUnitPutResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['units'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUnitPost
  public postOne(
    obj: WorkspaceUnitPostBody
  ): Observable<WorkspaceUnitPostResponse> {
    return this.onlineEndpoint.postOne(obj).pipe(
      map(
        (response: WorkspaceUnitPostResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['units'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceUnitGet
  public getOne(obj: WorkspaceUnitGetQuery): {
    local: Workspace['units'][0];
    promise: Observable<WorkspaceUnitGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getOne(obj),
      promise: this.onlineEndpoint.getOne(obj).pipe(
        map(
          (response: WorkspaceUnitGetResponse) => {
            this.offlineEndpoint.pushOrUpdate(
              response.main as Workspace['units'][0]
            );

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  /// CUSTOM METHODS

  ///
}
