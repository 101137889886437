<div class="cb-fields-customization-container">
    <table *ngIf="fields.length>0" class="cb-fields-customization-form">
        <thead>
            <tr>
                <th>{{"ENTITY.FIELD.NAME" | translate}}</th>
                <th>{{"ENTITY.FIELD.TYPE" | translate}}</th>
                <th>{{"ENTITY.FIELD.IS_ACTIVE" | translate}}</th>
                <th>{{"ENTITY.FIELD.IS_PUBLIC" | translate}}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <ng-container *ngFor="let field of fields; let idx = index">
                <ng-container *ngIf="field.name != 'category'">
                    <tr>
                        <td>
                            <cb-input [placeholder]="'ENTITY.FIELD.NAME' | translate"
                                [(ngModel)]="field.label"></cb-input>
                        </td>
                        <td>
                            <cb-select (change)="fieldTypeChanged(idx)" [placeholder]="'ENTITY.FIELD.TYPE' | translate"
                                [(ngModel)]="field.type" [options]="fieldTypeOptions"
                                [translateLabels]="true"></cb-select>
                        </td>
                        <td>
                            <cb-checkbox [(ngModel)]="field.isActive"></cb-checkbox>
                        </td>
                        <td>
                            <cb-checkbox [(ngModel)]="field.isPublic"></cb-checkbox>
                        </td>
                        <td>
                            <cb-icon [class.disabled]="!field.canDelete" name="trash" (click)="deleteField(idx)"
                                [tooltipDisabled]="field.canDelete" tooltipPosition="right"
                                [pTooltip]="'ENTITY.DISABLED_PROPERTY_DELETE_TOOLTIP' | translate"></cb-icon>
                        </td>
                    </tr>

                    <ng-container *ngIf="field.type == fieldTypes.select">
                        <tr class="cb-field-options-table">
                            <td colspan="5">
                                <table>
                                    <ng-container
                                        *ngFor="let option of field.options; let optIdx = index; trackBy: choiceTrackByFn">
                                        <tr>
                                            <td>
                                                <cb-input
                                                    [placeholder]="'ENTITY.FIELD.OPTION_N' | translate:{ idx: optIdx+1 }"
                                                    [(ngModel)]="field.options![optIdx]"></cb-input>
                                            </td>
                                            <td>
                                                <cb-icon name="trash"
                                                    (click)="deleteFieldOptions(idx, optIdx)"></cb-icon>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <tr class="cb-field-add-option" (click)="addOption(idx)">
                                        <td colspan="5">
                                            <div class="cb-field-add-button">
                                                <cb-icon name="add-circle"></cb-icon>
                                                <p>{{"ENTITY.ADD_OPTION" | translate}}</p>
                                            </div>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </ng-container>
        </tbody>
    </table>

    <cb-button type="dashed" icon="add-circle" (click)="addNewField()">{{"ENTITY.NEW_PROPERTY" | translate}}</cb-button>
</div>