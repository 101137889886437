import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManager } from 'src/app/classes/dataManager';
import { LifecyclesManager } from 'src/app/classes/lifecyclesManager';
import { UnitsManager } from 'src/app/classes/unitsManager';
import { Workspace } from 'src/app/classes/workspace';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { CbToastService } from 'src/app/widgets/cb-toast/cb-toast.service';
import { PhaseType } from '../../phases.module';
import { ActionFormWidgetComponent } from '../../widgets/action-form-widget/action-form-widget.component';
import { CbCustomField } from '../../widgets/fields-customization-widget/fields-customization-widget.component';
import * as uuid from 'uuid';
import { CbGlobalLoaderService } from 'src/app/widgets/cb-global-loader/cb-global-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-action-dialog',
  templateUrl: './new-action-dialog.component.html',
  styleUrls: ['./new-action-dialog.component.scss']
})
export class NewActionDialogComponent implements OnInit {

  public actionsOptions: { value: string, label: string, fields: CbCustomField[] }[] = [];

  public selectedAction?: string;
  public unit: Workspace["units"][0] | undefined = undefined;

  public currentStep: number = 0;
  public isLoading: boolean = false;

  @ViewChild("fieldsComponent") fieldsComponent: ActionFormWidgetComponent | undefined = undefined;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entity: any, type: PhaseType, unitId: string, lifecycle: Workspace["lifecycles"][0] },
    private readonly dialogRef: MatDialogRef<NewActionDialogComponent>,
    private readonly globalLoaderService: CbGlobalLoaderService,
    private readonly translate: TranslateService
  ) {
    if (data.unitId) {
      this.unit = UnitsManager.getInstance().units?.find((unit) => unit.id === data.unitId);

      this.actionsOptions = DataManager.getInstance().getActionsFromType(data.type);
    }
  }

  ngOnInit(): void {
    this.isLoading = true;
    this.load().then(() => {
      this.isLoading = false;
    });

  }

  public async load(): Promise<void> {
    await UnitsManager.getInstance().loadUnits();
  }

  public get selectedActionName(): string {
    if (this.selectedAction) {
      const action = this.actionsOptions.find((action) => action.value === this.selectedAction);
      if (action) {
        return action.label;
      }
    }
    return "";
  }

  public get selectedActionFields(): CbCustomField[] {
    if (this.selectedAction) {
      const action = this.actionsOptions.find((action) => action.value === this.selectedAction);
      if (action) {
        return action.fields;
      }
    }
    return [];
  }

  public get canGoForward(): boolean {
    return this.selectedAction !== undefined && this.selectedAction !== null;
  }

  public get canSubmit(): boolean {
    return this.fieldsComponent && (this.fieldsComponent.isLoadingAttachments || (this.fieldsComponent.values["period"] && this.fieldsComponent.values["period"].length == 0)) ? false : true;
  }

  public previousStep(): void {
    this.currentStep = 0;
  }

  public nextStep(): void {
    if (this.canGoForward)
      this.currentStep = 1;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public async submit(callbacks: CbLoadingButtonEvent): Promise<void> {
    if (!this.canSubmit) {
      callbacks.error.next();
      return
    }

    this.globalLoaderService.showLoader(
      true,
      this.translate.instant("COMMON.SAVING_BLOCKCHAIN"),
    );

    try {
      const values = this.fieldsComponent?.values;
      const lifecycle = { ...this.data.lifecycle };

      if (values) {

        const properties = Object.keys(values).filter((key) => key !== "period" && key !== "attachments").map((key) => {
          return {
            name: key,
            value: values[key]
          };
        });

        const attachments = values["attachments"]?.map((attachment: any) => attachment) || [];
        const period = values["period"]?.filter((val: any) => val).map((date: Date) => date.toISOString()) ?? [];
        const actionsFromOptions = this.actionsOptions.find((action) => action.value === this.selectedAction);

        lifecycle.actions.push({
          id: uuid.v4(),
          name: actionsFromOptions?.value || "",
          period: period,
          attachments: attachments,
          createdAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
          properties: properties || [],
        });

        await LifecyclesManager.getInstance().updateOrInsertLifecycle({ ...lifecycle });

        callbacks.success.next();
        setTimeout(() => {
          this.globalLoaderService.hideLoader();
          this.dialogRef.close();
        }, 200);
      } else {
        callbacks.error.next();
      }
    } catch (error) {
      this.globalLoaderService.hideLoader();
      CbToastService.instance.showHttpError(error);
      callbacks.error.next();
    }
  }
}
