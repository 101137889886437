import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Subject } from 'rxjs';

export interface CbLoadingButtonEvent {
  success: Subject<void>
  error: Subject<void>
}

@Component({
  selector: 'cb-button-loading',
  templateUrl: './cb-button-loading.component.html',
  styleUrls: ['./cb-button-loading.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CbButtonLoadingComponent implements OnInit {

  public buttonStatus: "normal" | "loading" | "error" | "confirmed" = "normal";

  public successSubject: Subject<void> = new Subject();
  public errorSubject: Subject<void> = new Subject();

  @Input() public progress: number | null = null;
  @Input() public disabled = false;
  @Input() public icon: string | undefined = undefined;
  @Input() public resetAfterSubmit = false;
  @Input() public type: string = "primary";

  @Output("submit") public onSubmit: EventEmitter<CbLoadingButtonEvent> = new EventEmitter<CbLoadingButtonEvent>();

  constructor(
    private readonly cdr: ChangeDetectorRef
  ) {
    this.successSubject.subscribe(() => { this.setConfirmStatus(); });
    this.errorSubject.subscribe(() => { this.setErrorStatus(); });
  }

  ngOnInit(): void {
  }

  public get buttonType(): string {
    switch (this.buttonStatus) {
      case "loading":
        return "neutral";
      case "error":
        return "danger";
      case "confirmed":
        return "success";
      default:
        return this.type;
    }
  }

  private setConfirmStatus(): void {
    this.buttonStatus = "confirmed";

    if (this.resetAfterSubmit)
      setTimeout(() => {
        this.buttonStatus = "normal";
        this.cdr.detectChanges();
      }, 1000);
  }
  private setErrorStatus(): void {
    this.buttonStatus = "error";

    setTimeout(() => {
      this.buttonStatus = "normal";
      this.cdr.detectChanges();
    }, 1000);
  }

  public onClickHandler(): void {
    if (!this.disabled && this.buttonStatus === "normal") {
      this.buttonStatus = "loading";
      this.cdr.detectChanges();

      this.onSubmit.emit({
        success: this.successSubject,
        error: this.errorSubject
      });
    }
  }
}
