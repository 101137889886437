<cb-dialog [width]="'400px'" [title]="((isEditing)? 'ENTITY.EDIT_ENTITY' : 'ENTITY.CREATE_NEW') | translate">
    <ng-container Content>
        <div class="cb-form-container">
            <cb-input [placeholder]="'ENTITY.FIELDS.IDENTIFIER_PLACEHOLDER' | translate"
                [label]="'ENTITY.FIELDS.IDENTIFIER' | translate" [(ngModel)]="entity['identifier']"></cb-input>
            <cb-input [placeholder]="'ENTITY.FIELDS.NAME_PLACEHOLDER' | translate"
                [label]="'ENTITY.FIELDS.NAME' | translate" [(ngModel)]="entity['name']"></cb-input>
            <ng-container *ngFor="let field of fields">
                <ng-container *ngIf="field.isActive">
                    <ng-container [ngSwitch]="field.type">
                        <ng-container *ngSwitchCase="fieldTypes.select">
                            <cb-select [placeholder]="field.label" [label]="field.label"
                                [(ngModel)]="entity.properties[field.name]"
                                [options]="getFieldOptions(field)"></cb-select>
                        </ng-container>

                        <ng-container *ngSwitchDefault="">
                            <cb-input
                                [placeholder]="field.label == 'Localizzazione' ? 'Incolla qui un link Google Maps' : field.label"
                                [label]="field.label" [(ngModel)]="entity.properties[field.name]"></cb-input>
                        </ng-container>
                    </ng-container> </ng-container>
            </ng-container>
        </div>
    </ng-container>

    <ng-container RightButtons>
        <cb-button-loading type="primary" (submit)="submit($event)">{{((isEditing)? "COMMON.SAVE" : "COMMON.CREATE") |
            translate}}</cb-button-loading>
    </ng-container>
</cb-dialog>