import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { environment } from 'src/environments/environment';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbInputComponent),
  multi: true,
};

@Component({
  selector: 'cb-input',
  templateUrl: './cb-input.component.html',
  styleUrls: ['./cb-input.component.scss', '../cb-form-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbInputComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() public type = 'text';
  @Input() public placeholder?: string = '';
  @Input() public label?: string = '';
  @Input() public autofocus?: boolean;
  @Input() public autocomplete?: string;
  @Input() public lowercase?: boolean;
  @Input() public disabled = false;
  @Input() public error = false;

  @Input() public regexCheck?: string;

  public showPassword = false;

  public regexError: boolean = false;

  @Output('input') public onInput: EventEmitter<string | number> =
    new EventEmitter<string | number>();
  @Output('change') public onChange: EventEmitter<string | number> =
    new EventEmitter<string | number>();

  @ViewChild('input') private inputRef?: ElementRef;

  //The internal data model
  private innerValue: string | number = '';

  constructor(private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.autofocus) this.focusInput();
  }

  ngAfterViewInit(): void {
    if (this.type == "number" && this.innerValue == "")
      this.innerValue = 0;
    this.setInputListener();
  }

  private setInputListener(): void { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  public get inputType(): string {
    return this.type === 'password' && this.showPassword ? 'text' : this.type;
  }

  // get accessor
  public get value(): string | number {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: any) {
    if (v !== this.innerValue) {
      if (this.type === 'number') v = Number(v);

      if (this.lowercase && v) v = v.toLowerCase();

      this.innerValue = v;

      this.onChangeCallback(v);
    }
  }

  public togglePassword(): void {
    this.showPassword = !this.showPassword;
    this.cdr.detectChanges();
  }

  // From ControlValueAccessor interface
  public writeValue(value: any) {
    if (!value && environment.inputCompiled) {
      this.onChangeCallback(this.innerValue);
      this.cdr.detectChanges();
    }
    else {
      if (value !== this.innerValue) {
        if (this.type === 'number') value = Number(value);
        if (this.lowercase && value) value = value.toLowerCase();
        this.innerValue = value;
        this.onChangeCallback(value);
        this.cdr.detectChanges();
      }
    }
  }

  public input(): void {
    this.onInput.emit(this.value);
  }
  public change(): void {
    if (this.regexCheck) {
      const regex = new RegExp(this.regexCheck);
      this.regexError = !regex.test(this.value.toString());
    }

    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public focusInput(): void {
    setTimeout(() => {
      this.inputRef?.nativeElement.focus();
    }, 0);
  }
}
