import { Component, Input, OnInit } from '@angular/core';

export enum CbButtonType {
  dark = 'dark',
  primary = 'primary',
  accent = 'accent',
  warning = 'warning',
  danger = 'danger',
  success = 'success',
  info = 'info',
  neutral = 'neutral',
  transparent = 'transparent',
}

@Component({
  selector: 'cb-button',
  templateUrl: './cb-button.component.html',
  styleUrls: ['./cb-button.component.scss'],
})
export class CbButtonComponent implements OnInit {
  public formLoading = false;
  public formSuccess = false;
  public formError = false;

  @Input() public disabled = false;
  @Input() public icon?: string;
  @Input() public type: CbButtonType | string = CbButtonType.neutral;
  @Input() public fullWidth = false;
  @Input() public tableButton = false;
  @Input() public iconPos: "left" | "right" = "left";

  constructor() { }

  ngOnInit(): void { }

  public click(event: MouseEvent): void {
    if (this.formLoading || this.formSuccess || this.formError || this.disabled) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  public setLoading(): void {
    this.formLoading = true;
  }

  public setSuccess(): void {
    this.type = CbButtonType.success;
    this.formLoading = false;
    this.formSuccess = true;
  }

  public setError(): void {
    const oldType = this.type;
    this.type = CbButtonType.danger;
    this.formError = true;

    setTimeout(() => {
      this.type = oldType;
      this.formError = false;
    });
  }
}
