import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { PHASES_LIST, PhaseType } from '../../phases.module';
import * as uuid from 'uuid';
import * as Fuse from 'fuse.js';
import { Workspace } from 'src/app/classes/workspace';
import { LifecyclesManager } from 'src/app/classes/lifecyclesManager';
import { lastValueFrom } from 'rxjs';
import { UnitsManager } from 'src/app/classes/unitsManager';
import { DataManager } from 'src/app/classes/dataManager';
import { CbGlobalLoaderService } from 'src/app/widgets/cb-global-loader/cb-global-loader.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-lifecycle-dialog',
  templateUrl: './new-lifecycle-dialog.component.html',
  styleUrls: ['./new-lifecycle-dialog.component.scss']
})
export class NewLifecycleDialogComponent implements OnInit {

  public readonly resultsPerPage: number = 4;
  public currentPage: number = 0;

  public isLoading: boolean = false;
  public currentPhaseIndex: number = -1;

  public name: string = "";
  public unit: Workspace["units"][0] | undefined = undefined;

  public searchText: string = "";

  public shownResults: {
    id: string,
    identifier: string,
    type: PhaseType,
    period: Date[],
    unitName: string,
    unitIdentifier: string,
    selected: boolean,
    unitId: string,
  }[] = [];

  public results: {
    id: string,
    identifier: string,
    type: PhaseType,
    period: Date[],
    unitName: string,
    unitIdentifier: string,
    selected: boolean,
    unitId: string,
  }[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entity: any, type: PhaseType, unitId: string, name: string },
    private readonly dialogRef: MatDialogRef<NewLifecycleDialogComponent>,
    private readonly globalLoaderService: CbGlobalLoaderService,
    private readonly translate: TranslateService
  ) {
    this.currentPhaseIndex = PHASES_LIST.findIndex((phase) => phase.type === data.type);
    if (data.name) {
      this.name = data.name;
    }
    if (data.unitId) {
      this.unit = UnitsManager.getInstance().units?.find((unit) => unit.id === data.unitId);
    }

  }

  ngOnInit(): void {
    this.isLoading = true;
    this.loadPreviousLifecycles().then(() => {
      this.isLoading = false;
    });
  }

  public async loadPreviousLifecycles(): Promise<void> {
    await LifecyclesManager.getInstance().loadLifecycles(true);
    await UnitsManager.getInstance().loadUnits(true);
    const units = UnitsManager.getInstance().units ?? [];
    const lifecycles = LifecyclesManager.getInstance().lifecycles ?? [];
    const enabledUnits = DataManager.getInstance().enabledUnits ?? [];

    if (this.hasAncestors) {
      const previousPhase = this.previousPhase;
      const type = previousPhase.type;
      units.filter((unit) => unit.unitType === type).forEach((unit) => {
        const filteredLifecycles = lifecycles.filter((lifecycle) => lifecycle.unitId === unit.id);
        for (let lifecycle of filteredLifecycles) {
          let dates = [];
          const actions = lifecycle.actions ?? [];
          for (let action of actions) {
            if (action.period[0])
              dates.push(new Date(action.period[0]));
            if (action.period[1])
              dates.push(new Date(action.period[1]));
          }
          dates.sort((a, b) => a.getTime() - b.getTime());
          const minDate = dates[0];
          const maxDate = dates[dates.length - 1];

          this.results.push({
            id: lifecycle.id,
            identifier: lifecycle.identifier,
            type: previousPhase.type,
            period: lifecycle.createdAt && lifecycle.updatedAt && lifecycle.status == 'closed' ? [minDate, maxDate] : minDate ? [minDate] : [],
            unitId: unit.id,
            unitName: unit.name,
            selected: false,
            unitIdentifier: unit.identifier,
          });
        }
      })
      this.searchResults();
    }
  }

  public get previousPhase() {
    return PHASES_LIST[this.currentPhaseIndex - 1];
  }

  public get currentPhase() {
    return PHASES_LIST[this.currentPhaseIndex];
  }

  public get hasAncestors(): boolean {
    return this.currentPhaseIndex > 0;
  }

  public searchResults() {
    if (this.searchText.length < 3) {
      this.shownResults = [];
      for (let result of this.results)
        this.shownResults.push({ ...result });
      this.currentPage = 0;
    } else {
      console.log("Searching", this.searchText, this.results);
      const fuseOption = {
        keys: ["identifier", "unitIdentifier", "unitName"],
        threshold: 0.3,
        includeScore: true,
      };

      const fuseInstance = new Fuse.default(this.results, fuseOption);
      const results = fuseInstance.search(this.searchText);
      this.shownResults = results.map((result) => result.item);
      this.currentPage = 0;
    }

    console.log("Results", this.shownResults);
  }

  public get totalPages(): number {
    return Math.ceil(this.shownResults.length / this.resultsPerPage);
  }

  public get canGoBack(): boolean {
    return this.currentPage > 0;
  }

  public get canGoNext(): boolean {
    return this.currentPage < this.totalPages - 1;
  }

  public nextPage(): void {
    if (this.canGoNext)
      this.currentPage++;
  }

  public previousPage(): void {
    if (this.canGoBack)
      this.currentPage--;
  }

  public async submit(callbacks: CbLoadingButtonEvent): Promise<void> {

    this.globalLoaderService.showLoader(
      true,
      this.translate.instant("COMMON.SAVING_BLOCKCHAIN"),
    );

    const lifecycle: Workspace["lifecycles"][0] = {
      id: uuid.v4(),
      identifier: this.name,
      status: "inprogress",
      unitId: this.data.unitId,
      dependencyIds: this.results.filter((result) => result.selected).map((result) => result.id),
      actions: [],
    };

    try {
      const result = await (LifecyclesManager.getInstance().updateOrInsertLifecycle(lifecycle));
      callbacks.success.next();

      setTimeout(() => {
        this.globalLoaderService.hideLoader();
        this.dialogRef.close({ id: lifecycle.id });
      }, 200);
    } catch (error) {
      this.globalLoaderService.hideLoader();
      console.error(error);
      callbacks.error.next();
    }

  }

  public changeSelected(): void {
    this.results = this.results.filter((result) => !this.shownResults.find((shownResult) => shownResult.id === result.id));
    this.results = this.results.concat(this.shownResults);
  }

  public get selectedCount(): number {
    return this.results.filter((result) => result.selected).length;
  }
}
