import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from './currency';
import { SmartDateTimePipe } from './smart-date';
import { FileSizePipe } from './file-size';
import { InitialsPipe } from './intials-pipe';
import { SecondsTimePipe } from './secondsTime';
import { PaginationPipe } from './pagination';
import { ActionsNamePipe } from './actions-pipe';



@NgModule({
  declarations: [
    CurrencyPipe,
    SmartDateTimePipe,
    FileSizePipe,
    InitialsPipe,
    SecondsTimePipe,
    ActionsNamePipe,
    PaginationPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CurrencyPipe,
    SmartDateTimePipe,
    FileSizePipe,
    InitialsPipe,
    ActionsNamePipe,
    SecondsTimePipe,
    PaginationPipe
  ]
})
export class CommonPipesModule { }
