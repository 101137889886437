import { lastValueFrom } from "rxjs";
import { PhaseType } from "../modules/phases/phases.module";
import { LifecyclesPresenter } from "./presenters/lifecyclesPresenter";
import { UserPresenter } from "./presenters/userPresenter";
import { UsersPresenter } from "./presenters/usersPresenter";
import { User } from "./user";
import { Workspace, WorkspaceBasicAdditionalPropertie, WorkspaceBasicSchemaAction } from "./workspace";

export class LifecyclesManager {

    // Singleton for data manager
    private static instance: LifecyclesManager;
    private lifecyclesPresenter = new LifecyclesPresenter();

    public lifecycles: Workspace["lifecycles"] | undefined = undefined;

    public lifecyclesFromUnitId(id: string): Workspace["lifecycles"] {
        return this.lifecycles?.filter((unit) => unit.unitId === id) ?? [];
    }

    public static getInstance(): LifecyclesManager {
        if (!LifecyclesManager.instance) {
            LifecyclesManager.instance = new LifecyclesManager();
        }
        return LifecyclesManager.instance;
    }

    public async loadLifecycles(force = false): Promise<void> {
        if (!this.lifecycles || force) {
            const result = await lastValueFrom(this.lifecyclesPresenter.getManyRequested({}).promise);
            this.lifecycles = result.main as Workspace["lifecycles"];
        }
    }

    public async canDeleteFromLifecycleId(id: string): Promise<boolean> {
        await this.loadLifecycles();
        const lifecycles = this.lifecyclesFromUnitId(id);
        return lifecycles.length === 0;
    }

    public async updateOrInsertLifecycle(obj: Workspace["lifecycles"][0]): Promise<void> {
        await this.loadLifecycles();
        const found = this.lifecycles?.find((u) => u.id === obj.id);
        if (found) {
            await lastValueFrom(this.lifecyclesPresenter.putOneRequested(obj));
        } else {
            await lastValueFrom(this.lifecyclesPresenter.postOneRequested(obj));
        }
        await this.loadLifecycles(true);
    }

    public async deleteLifecycle(id: string): Promise<void> {
        await lastValueFrom(this.lifecyclesPresenter.deleteOneRequested({ id: id }));
        const index = this.lifecycles?.findIndex((u) => u.id === id);
        if (index !== undefined && index !== -1) {
            this.lifecycles?.splice(index, 1);
        }
        await this.loadLifecycles(true);
    }
}