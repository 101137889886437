/// IMPORTS
import {
  WorkspaceLifecycleGetQuery,
  WorkspaceLifecycleGetResponse,
  WorkspaceLifecyclePostBody,
  WorkspaceLifecyclePostResponse,
  WorkspaceLifecyclePutBody,
  WorkspaceLifecyclePutResponse,
  WorkspaceLifecycleDeleteQuery,
  WorkspaceLifecycleDeleteResponse,
  WorkspaceLifecyclesGetQuery,
  WorkspaceLifecyclesGetResponse,
  WorkspaceLifecyclePostsBody,
  WorkspaceLifecyclePostsResponse,
  WorkspaceLifecyclePutsBody,
  WorkspaceLifecyclePutsResponse,
  WorkspaceLifecycleDeletesQuery,
  WorkspaceLifecycleDeletesResponse,
  WorkspaceLifecycleOrderPutBody,
  WorkspaceLifecycleOrderPutResponse,
} from 'generatedInterfaces';
import { LifecyclesOfflineEndpoint } from 'src/app/classes/offlineEndpoints/lifecyclesOfflineEndpoint';
import { LifecyclesOnlineEndpoint } from 'src/app/classes/onlineEndpoints/lifecyclesOnlineEndpoint';
import { HttpErrorResponse } from '@angular/common/http';
import { Workspace } from '../workspace';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class LifecyclesModel {
  /// PROPERTIES
  private onlineEndpoint: LifecyclesOnlineEndpoint =
    new LifecyclesOnlineEndpoint();
  private offlineEndpoint: LifecyclesOfflineEndpoint =
    new LifecyclesOfflineEndpoint();

  /// CUSTOM PROPERTIES

  ///

  constructor() {
    this.customConstructor();
  }

  customConstructor() {
    /// CUSTOM CONSTRUCTOR CONTENT
    ///
  }

  /// METHODS

  // workspaceLifecycleOrderPut
  public changeOrder(
    obj: WorkspaceLifecycleOrderPutBody
  ): Observable<WorkspaceLifecycleOrderPutResponse> {
    return this.onlineEndpoint.changeOrder(obj).pipe(
      map(
        (response: WorkspaceLifecycleOrderPutResponse) => {
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLifecycleDeletes
  public deletesMany(
    obj: WorkspaceLifecycleDeletesQuery
  ): Observable<WorkspaceLifecycleDeletesResponse> {
    return this.onlineEndpoint.deletesMany(obj).pipe(
      map(
        (response: WorkspaceLifecycleDeletesResponse) => {
          const objs = obj.ids.map((val) => {
            return { id: val };
          });
          for (let obj of objs)
            this.offlineEndpoint.delete(obj as Workspace['lifecycles'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLifecyclePuts
  public putsMany(
    obj: WorkspaceLifecyclePutsBody
  ): Observable<WorkspaceLifecyclePutsResponse> {
    return this.onlineEndpoint.putsMany(obj).pipe(
      map(
        (response: WorkspaceLifecyclePutsResponse) => {
          const objs = obj.bodies;
          for (let obj of objs)
            this.offlineEndpoint.pushOrUpdate(
              obj as Workspace['lifecycles'][0]
            );
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLifecyclePosts
  public postsMany(
    obj: WorkspaceLifecyclePostsBody
  ): Observable<WorkspaceLifecyclePostsResponse> {
    return this.onlineEndpoint.postsMany(obj).pipe(
      map(
        (response: WorkspaceLifecyclePostsResponse) => {
          const objs = obj.bodies;
          for (let obj of objs)
            this.offlineEndpoint.pushOrUpdate(
              obj as Workspace['lifecycles'][0]
            );
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLifecyclesGet
  public getMany(obj: WorkspaceLifecyclesGetQuery): {
    local: Workspace['lifecycles'];
    promise: Observable<WorkspaceLifecyclesGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getMany(obj).filter((item) => {
        for (let key of Object.keys(obj)) {
          if ((<any>item)[key] != (<any>obj)[key]) {
            return false;
          }
        }
        return true;
      }),
      promise: this.onlineEndpoint.getMany(obj).pipe(
        map(
          (response: WorkspaceLifecyclesGetResponse) => {
            let mains = response.main;
            if (mains)
              this.offlineEndpoint.setEntities(
                mains as Workspace['lifecycles']
              );

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  // workspaceLifecycleDelete
  public deleteOne(
    obj: WorkspaceLifecycleDeleteQuery
  ): Observable<WorkspaceLifecycleDeleteResponse> {
    return this.onlineEndpoint.deleteOne(obj).pipe(
      map(
        (response: WorkspaceLifecycleDeleteResponse) => {
          this.offlineEndpoint.delete(obj as Workspace['lifecycles'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLifecyclePut
  public putOne(
    obj: WorkspaceLifecyclePutBody
  ): Observable<WorkspaceLifecyclePutResponse> {
    return this.onlineEndpoint.putOne(obj).pipe(
      map(
        (response: WorkspaceLifecyclePutResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['lifecycles'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLifecyclePost
  public postOne(
    obj: WorkspaceLifecyclePostBody
  ): Observable<WorkspaceLifecyclePostResponse> {
    return this.onlineEndpoint.postOne(obj).pipe(
      map(
        (response: WorkspaceLifecyclePostResponse) => {
          this.offlineEndpoint.pushOrUpdate(obj as Workspace['lifecycles'][0]);
          return response;
        },
        catchError((err: HttpErrorResponse) => {
          return throwError(() => err);
        })
      )
    );
  }

  // workspaceLifecycleGet
  public getOne(obj: WorkspaceLifecycleGetQuery): {
    local: Workspace['lifecycles'][0];
    promise: Observable<WorkspaceLifecycleGetResponse>;
  } {
    return {
      local: this.offlineEndpoint.getOne(obj),
      promise: this.onlineEndpoint.getOne(obj).pipe(
        map(
          (response: WorkspaceLifecycleGetResponse) => {
            this.offlineEndpoint.pushOrUpdate(
              response.main as Workspace['lifecycles'][0]
            );

            return response;
          },
          catchError((err: HttpErrorResponse) => {
            return throwError(() => err);
          })
        )
      ),
    };
  }

  /// CUSTOM METHODS

  ///
}
