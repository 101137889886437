<div class="cb-page-wrapper" *ngIf="isInitView">
  <ng-container *ngIf="showSidebar">
    <div class="cb-page-sidebar">
      <cb-sidebar></cb-sidebar>
    </div>
  </ng-container>
  <div class="cb-page-container">
    <router-outlet>
    </router-outlet>
  </div>
</div>


<cb-toast-message [multiple]="true" [defaultDuration]="5000" [maxToasts]="3"></cb-toast-message>
<cb-global-loader></cb-global-loader>