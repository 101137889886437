import { last, lastValueFrom } from "rxjs";
import { PhaseType } from "../modules/phases/phases.module";
import { CbCustomField, CbCustomFieldType } from "../modules/phases/widgets/fields-customization-widget/fields-customization-widget.component";
import { CbLoadingButtonEvent } from "../widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component";
import { BasicPresenter } from "./presenters/basicPresenter";
import { UnitsManager } from "./unitsManager";
import { Workspace, WorkspaceBasicAdditionalPropertie, WorkspaceBasicSchemaAction } from "./workspace";

export class DataManager {

    // Singleton for data manager
    private static instance: DataManager;
    private basicPresenter = new BasicPresenter();
    public basicItem: Workspace["basic"] | undefined = undefined;

    public get enabledUnits(): string[] {
        return this.basicItem?.enabledUnits ?? [];
    }

    public get title(): string {
        return this.basicItem?.title ?? "";
    }

    public get getVineyardCategories(): string[] {
        return this.basicItem?.categories?.filter(c => c.entity === "vineyards")?.map(c => c.name) ?? [];
    }
    public get getWinemakingCategories(): string[] {
        return this.basicItem?.categories?.filter(c => c.entity === "winemakings")?.map(c => c.name) ?? [];
    }
    public get getLotCategories(): string[] {
        return this.basicItem?.categories?.filter(c => c.entity === "lots")?.map(c => c.name) ?? [];
    }

    public get getVineyardProperties(): WorkspaceBasicAdditionalPropertie[] { return (this.basicItem?.additionalProperties ?? []).filter((prop) => prop.unitType === "vineyards") };
    public get getWinemakingProperties(): WorkspaceBasicAdditionalPropertie[] { return (this.basicItem?.additionalProperties ?? []).filter((prop) => prop.unitType === "winemakings") };
    public get getLotProperties(): WorkspaceBasicAdditionalPropertie[] { return (this.basicItem?.additionalProperties ?? []).filter((prop) => prop.unitType === "lots") };

    public get getVineyardActions(): WorkspaceBasicSchemaAction[] { return (this.basicItem?.schemaActions ?? []).filter((prop) => prop.unitType === "vineyards") };
    public get getWinemakingActions(): WorkspaceBasicSchemaAction[] { return (this.basicItem?.schemaActions ?? []).filter((prop) => prop.unitType === "winemakings") };
    public get getLotActions(): WorkspaceBasicSchemaAction[] { return (this.basicItem?.schemaActions ?? []).filter((prop) => prop.unitType === "lots") };

    public static getInstance(): DataManager {
        if (!DataManager.instance) {
            DataManager.instance = new DataManager();
        }
        return DataManager.instance;
    }

    public async setBasic(id: string): Promise<void> {
        this.basicPresenter.setSelectedRequested(id);
        await this.loadBasic(true)
    }

    public async loadBasic(force = false): Promise<void> {
        if (!this.basicItem || force) {
            const result = await lastValueFrom(this.basicPresenter.getOneRequested({ id: this.basicPresenter.getSelectedRequested() }).promise);
            this.basicItem = <any>result.main;
        }
    }

    public static slugify(text: string): string {
        return text
            .toString()
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .trim()
            .replace(/[^a-z0-9 ]/g, "")
            .replace(/\s+/g, "-");
    }

    public fromFieldsToPhaseProperties(fields: CbCustomField[]): WorkspaceBasicAdditionalPropertie[] {
        const result = fields.map(f => {
            const type = f.type == CbCustomFieldType.text ? "text" : f.type == CbCustomFieldType.select ? "enum" : "boolean";
            const options = f.type == CbCustomFieldType.select ? (f.options ?? []).map((val) => ({ label: { it: val ?? "" }, value: val ?? "" })) : [];
            const name = f.name.length > 0 ? f.name : DataManager.slugify(f.label ?? "");
            const result: WorkspaceBasicAdditionalPropertie = { label: { it: f.label }, fieldName: name, type: type, isShown: f.isPublic, isEnabled: f.isActive, options: options };
            return result;
        });
        return result;
    }

    public async fromPhaseToActionsWithField(type: PhaseType) {
        let result: { name: string, label: string, fields: CbCustomField[] }[] = [];
        const propertyActions = this.basicItem?.schemaActions ?? [];
        for (let action of propertyActions) {
            if ((action.unitType == "lots" && type == PhaseType.lot) || (action.unitType == "winemakings" && type == PhaseType.winemaking) || (action.unitType == "vineyards" && type == PhaseType.vineyard)) {
                result.push({ name: action.name, label: action.label["it"] ?? "", fields: this.fromPropertiesToCbCustomFields(action.properties, new Map<string, boolean>(), type) });
            }
        }

        return result;
    }

    public async fromActionsWithFieldToSchemaActions(actions: { name: string, label: string, fields: CbCustomField[] }[], type: PhaseType) {
        let result: WorkspaceBasicSchemaAction[] = [];
        for (let action of actions) {
            const properties = this.fromFieldsToPhaseProperties(action.fields);
            const unitType = type == PhaseType.lot ? "lots" : type == PhaseType.winemaking ? "winemakings" : "vineyards";
            result.push({ name: action.name, label: { it: action.label }, unitType: unitType, properties: properties, isEnabled: true, isShown: true });
        }
        return result;
    }

    public async fromPhasePropertiesToFields(type: PhaseType): Promise<CbCustomField[]> {
        let properties = [];

        if (type == PhaseType.vineyard) {
            properties = this.getVineyardProperties;
        } else if (type == PhaseType.winemaking) {
            properties = this.getWinemakingProperties;
        } else {
            properties = this.getLotProperties;
        }

        let canDeleteMap = new Map<string, boolean>();
        for (let prop of properties) {
            let result = await this.canDeleteInType(type);
            canDeleteMap.set(prop.fieldName, result);
        }

        const result = this.fromPropertiesToCbCustomFields(properties, canDeleteMap, type);

        return result;
    }


    private fromPropertiesToCbCustomFields(properties: WorkspaceBasicAdditionalPropertie[], canDeleteMap: Map<string, boolean>, type: PhaseType) {
        return properties.map(p => {
            const canDelete = canDeleteMap.get(p.fieldName) ?? false;
            const fieldType = p.type == "text" ? CbCustomFieldType.text : p.type == "enum" ? CbCustomFieldType.select : CbCustomFieldType.boolean;
            let options = p.type == "enum" ? p.options.map((val) => val.label["it"] ?? "") : [];
            if (p.fieldName == "category") {
                options = type == "lots" ? this.getLotCategories : type == "winemakings" ? this.getWinemakingCategories : this.getVineyardCategories;
            }
            return { type: fieldType, name: p.fieldName, label: p.label["it"] ?? "", isActive: p.isEnabled ?? false, isPublic: p.isShown ?? false, canDelete: canDelete, options: options };
        });
    }

    private async canDeleteInType(type: PhaseType): Promise<boolean> {
        const canDelete = await UnitsManager.getInstance().canDeleteType(type);
        return canDelete;
    }

    public async updatePhaseProperties(type: PhaseType, properties: WorkspaceBasicAdditionalPropertie[], event: CbLoadingButtonEvent): Promise<void> {
        if (!this.basicItem) return;
        for (let prop of properties) {
            prop.unitType = type == PhaseType.vineyard ? "vineyards" : type == PhaseType.winemaking ? "winemakings" : "lots";
            prop.isUnit = true;
        }
        if (type == PhaseType.vineyard) {
            this.basicItem.additionalProperties = this.basicItem.additionalProperties.filter((prop) => prop.unitType != "vineyards");
            this.basicItem.additionalProperties.push(...properties);
        } else if (type == PhaseType.winemaking) {
            this.basicItem.additionalProperties = this.basicItem.additionalProperties.filter((prop) => prop.unitType != "winemakings");
            this.basicItem.additionalProperties.push(...properties);
        } else {
            this.basicItem.additionalProperties = this.basicItem.additionalProperties.filter((prop) => prop.unitType != "lots");
            this.basicItem.additionalProperties.push(...properties);
        }

        try {
            await lastValueFrom(this.basicPresenter.putOneRequested(this.basicItem));
            event.success.next();
        } catch (error) {
            event.error.next();
            console.error(error)
        }
    }

    public async updatePhaseActions(type: PhaseType, actions: { name: string, label: string, fields: CbCustomField[] }[], event: CbLoadingButtonEvent): Promise<void> {
        if (!this.basicItem) return;
        // Filter the schemaActions by type
        this.basicItem.schemaActions = this.basicItem.schemaActions.filter((action) => {
            if (type == PhaseType.vineyard) {
                return action.unitType != "vineyards";
            } else if (type == PhaseType.winemaking) {
                return action.unitType != "winemakings";
            } else {
                return action.unitType != "lots";
            }
        });

        // Add the new actions
        const newActions = await this.fromActionsWithFieldToSchemaActions(actions, type);
        this.basicItem.schemaActions.push(...newActions);

        try {
            await lastValueFrom(this.basicPresenter.putOneRequested(this.basicItem));
            event.success.next();
        } catch (error) {
            event.error.next();
            console.error(error)
        }
    }

    public getActionsFromType(type: PhaseType): { value: string, label: string, fields: CbCustomField[] }[] {
        if (!this.basicItem) return [];
        const actions = this.basicItem.schemaActions.filter((action) => {
            if (type == PhaseType.vineyard) {
                return action.unitType == "vineyards";
            } else if (type == PhaseType.winemaking) {
                return action.unitType == "winemakings";
            } else {
                return action.unitType == "lots";
            }
        });

        return actions.map((action) => {
            return { value: action.name, label: action.label["it"] ?? "", fields: this.fromPropertiesToCbCustomFields(action.properties, new Map<string, boolean>(), type) };
        });
    }

}