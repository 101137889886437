<div class="cb-topbar-user-container" (click)="UserPanel.toggle($event)">
  <cb-user shape="circle" size="large" [username]="username" [picture]="picture">
  </cb-user>
  <cb-icon *ngIf="isAdmin" class="cb-verified-icon" name="verified"></cb-icon>
  <cb-icon name="chevron-down"></cb-icon>
</div>

<p-overlayPanel #UserPanel styleClass="cb-user-panel">
  <ng-template pTemplate>
    <cb-settings-popover [settingsList]="(isAdmin)? adminPopoverOptions : popoverOptions"
      (settingClicked)="optionSelected($event)"></cb-settings-popover>
  </ng-template>
</p-overlayPanel>