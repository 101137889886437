<cb-dialog [width]="'500px'" [closeButton]="false">
    <ng-container Title>
        <div class="cb-dialog-entity-title">
            <h4>{{((isEditingProperties)? "ENTITY.EDITING_ACTION_PROPERTIES" : "ENTITY.EDIT_ACTIONS") | translate}}</h4>
            <div class="cb-dialog-info">
                <p>{{ selectedPhase.name | translate }}</p>
            </div>
        </div>
    </ng-container>

    <ng-container Content>
        <ng-container *ngIf="!isEditingProperties; else PropertiesEditTemplate">
            <div class="cb-fields-customization-container">
                <table *ngIf="actions.length>0" class="cb-fields-customization-form">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{{"ENTITY.FIELD.NAME" | translate}}</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody cdkDropList [cdkDropListData]="actions" (cdkDropListDropped)="drop($event)">
                        <ng-container *ngFor="let action of actions; let idx = index">
                            <tr cdkDrag cdkDragLockAxis="y">
                                <td class="cb-drag">
                                    <cb-icon cdkDragHandle name="drag-handle"></cb-icon>
                                </td>
                                <td class="cb-field-name">
                                    <cb-input [placeholder]="'ENTITY.FIELD.NAME' | translate"
                                        [(ngModel)]="action.label"></cb-input>
                                </td>
                                <td>
                                    <cb-button (click)="editActionProperties(idx)"
                                        icon="edit">{{"ENTITY.EDIT_PROPERTIES" | translate}}</cb-button>
                                </td>
                                <td>
                                    <cb-icon name="trash" (click)="deleteAction(idx)"></cb-icon>
                                </td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <cb-button type="dashed" icon="add-circle" (click)="addNewAction()">{{"ENTITY.NEW_PROPERTY" |
                    translate}}</cb-button>
            </div>
        </ng-container>
        <ng-template #PropertiesEditTemplate>
            <app-fields-customization-widget [inputFields]="currentActionProperties"></app-fields-customization-widget>
        </ng-template>
    </ng-container>


    <ng-container LeftButtons>
        <cb-button (click)="cancel()">{{"COMMON.CANCEL" | translate}}</cb-button>
    </ng-container>

    <ng-container RightButtons>
        <ng-container *ngIf="!isEditingProperties; else EditingPropertiesButtonsTemplate">
            <cb-button-loading (submit)="submit($event)" type="success">{{"COMMON.SAVE" |
                translate}}</cb-button-loading>
        </ng-container>
        <ng-template #EditingPropertiesButtonsTemplate>
            <cb-button-loading (submit)="saveActionFields($event)" type="success">{{"COMMON.SAVE" |
                translate}}</cb-button-loading>
        </ng-template>
    </ng-container>
</cb-dialog>