import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManager } from 'src/app/classes/dataManager';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { CbToastService } from 'src/app/widgets/cb-toast/cb-toast.service';
import { PHASES_LIST, PhaseType } from '../../phases.module';
import { CbCustomField, FieldsCustomizationWidgetComponent } from '../../widgets/fields-customization-widget/fields-customization-widget.component';

export interface EntityAction {
  name: string;
  label: string;
  fields: CbCustomField[];
}

@Component({
  selector: 'app-edit-entity-actions-dialog',
  templateUrl: './edit-entity-actions-dialog.component.html',
  styleUrls: ['./edit-entity-actions-dialog.component.scss']
})
export class EditEntityActionsDialogComponent implements OnInit {

  public editingActionIndex: number | undefined;
  public isLoading: boolean = false;

  public actions: EntityAction[] = [];

  @ViewChild(FieldsCustomizationWidgetComponent) fieldsCustomizationWidget!: FieldsCustomizationWidgetComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: PhaseType },
    private readonly dialogRef: MatDialogRef<EditEntityActionsDialogComponent>
  ) {
    // TODO: get actions from backend
  }

  ngOnInit(): void {
    this.loadActions();
  }

  private async loadActions(): Promise<void> {
    this.isLoading = true;
    try {
      const actions = await DataManager.getInstance().fromPhaseToActionsWithField(this.data.type);
      for (const action of actions) {
        this.actions.push({ ...action })
      }
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  public get isEditingProperties(): boolean {
    return this.editingActionIndex !== undefined;
  }

  public get currentActionProperties(): CbCustomField[] {
    return (this.isEditingProperties) ? this.actions[this.editingActionIndex!].fields : [];
  }

  public get selectedPhase() {
    return PHASES_LIST.find(phase => phase.type === this.data.type) ?? PHASES_LIST[0];
  }

  public drop(event: CdkDragDrop<EntityAction[]>) {
    moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
  }

  public editActionProperties(index: number): void {
    this.editingActionIndex = index;
  }

  public deleteAction(index: number): void {
    this.actions.splice(index, 1);
  }

  public addNewAction(): void {
    this.actions.push({ name: '', label: '', fields: [] });
  }

  public cancel(): void {
    if (this.isEditingProperties) {
      this.editingActionIndex = undefined;
    } else {
      this.dialogRef.close();
    }
  }

  public saveActionFields(callbacks: CbLoadingButtonEvent): void {
    this.actions[this.editingActionIndex!].fields = this.fieldsCustomizationWidget.fields;

    // TODO: save to backend
    // then
    callbacks.success.next();
    this.editingActionIndex = undefined;
  }

  public async submit(callbacks: CbLoadingButtonEvent): Promise<void> {
    for (const action of this.actions) {
      action.name = DataManager.slugify(action.label);
    }

    try {
      await DataManager.getInstance().updatePhaseActions(this.data.type, this.actions, callbacks);
      setTimeout(() => {
        this.dialogRef.close();
      }, 200);
    } catch (error) {
      CbToastService.instance.showHttpError(error);
    }

  }
}
