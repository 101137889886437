import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

const noop = () => { };

const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CbSearchComponent),
  multi: true,
};

@Component({
  selector: 'cb-search',
  templateUrl: './cb-search.component.html',
  styleUrls: ['./cb-search.component.scss', '../cb-form-style.scss'],
  providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR],
})
export class CbSearchComponent implements ControlValueAccessor, OnInit, AfterViewInit {
  @Input() public placeholder?: string = '';
  @Input() public autofocus?: boolean;
  @Input() public autocomplete?: string;
  @Input() public disabled = false;

  @Output('input') public onInput: EventEmitter<string | number> =
    new EventEmitter<string | number>();
  @Output('change') public onChange: EventEmitter<string | number> =
    new EventEmitter<string | number>();

  @ViewChild('input') private inputRef?: ElementRef;

  //The internal data model
  private innerValue = '';

  constructor(private readonly cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    if (this.autofocus) this.focusInput();

  }

  ngAfterViewInit(): void {
    this.setInputListener();
  }

  private setInputListener(): void { }

  // Placeholders for the callbacks which are later provided
  // by the Control Value Accessor
  private onTouchedCallback: () => void = noop;
  private onChangeCallback: (_: any) => void = noop;

  // get accessor
  public get value(): string {
    return this.innerValue;
  }

  // Set accessor including call the onchange callback
  public set value(v: string) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(v);
    }
  }

  // From ControlValueAccessor interface
  public writeValue(value: string) {
    if (value !== this.innerValue) {
      this.innerValue = value;
      this.onChangeCallback(value);
      this.cdr.detectChanges();
    }
  }

  public input(): void {
    this.onInput.emit(this.value);
  }
  public change(): void {
    this.onChange.emit(this.value);
  }

  // From ControlValueAccessor interface
  public registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  // From ControlValueAccessor interface
  public registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  public focusInput(): void {
    setTimeout(() => {
      this.inputRef?.nativeElement.focus();
    }, 0);
  }
}
