<div class="cb-page-content">
    <div class="cb-page-header">
      <cb-topbar [title]="unit?.name ?? ''" [backButton]="true"
        [goBackLocation]="!isMobile || selectedActionIndex==undefined" (goBack)="goBackToActions()"></cb-topbar>
    </div>
    <div class="cb-page">
      <div class="cb-entity-page">
  
        <ng-container *ngIf="!isLoading">
          <!-- DESKTOP VERSION -->
          <ng-container *ngIf="!isMobile; else MobileTemplate">
            <!-- LIFECYCLES LIST -->
            <ng-container *ngIf="!isSingleLifecycle">
              <ng-container *ngTemplateOutlet="LifecyclesListTemplate"></ng-container>
            </ng-container>
  
            <!-- SELECTED LIFECYCLE ACTIONS LIST -->
            <ng-container *ngTemplateOutlet="ActionsListTemplate"></ng-container>
  
            <!-- SELECTED ACTION LIST -->
            <ng-container *ngTemplateOutlet="ActionFormTemplate"></ng-container>
          </ng-container>
  
          <!-- MOBILE VERSION-->
          <ng-template #MobileTemplate>
            <!-- SELECTED LIFECYCLE ACTIONS LIST -->
            <ng-container *ngIf="selectedActionIndex==undefined; else SelectedActionTemplate">
              <ng-container *ngTemplateOutlet="ActionsListTemplate"></ng-container>
            </ng-container>
  
            <!-- SELECTED ACTION LIST -->
            <ng-template #SelectedActionTemplate>
              <ng-container *ngTemplateOutlet="ActionFormTemplate"></ng-container>
            </ng-template>
          </ng-template>
        </ng-container>
  
      </div>
    </div>
  </div>
  
  
  <ng-template #LifecyclesListTemplate>
    <div class="cb-entity-lifecycles-section">
      <!-- NEW LIFECYCLE BUTTON-->
      <cb-button (click)="newLifecycle()" [disabled]="!canCreateNewLifecycle" [tooltipDisabled]="canCreateNewLifecycle"
        [type]="(canCreateNewLifecycle)? 'primary' : 'dashed'" icon="add-circle" tooltipPosition="top"
        [pTooltip]="'ENTITY.NEW_LIFECYCLE_DISABLED_TOOLTIP' | translate">{{"ENTITY.NEW_LIFECYCLE" |
        translate}}</cb-button>
  
      <!-- LIFECYCLES -->
      <ul class="cb-selection-list">
        <ng-container *ngFor="let lifecycle of lifecycles; let idx=index">
          <li *ngVar="selectedLifecycleIndex==idx as isSelected" [class.selected]="isSelected"
            (click)="selectLifecycle(idx)">
            <div class="cb-selection-list-title">
              <p>{{lifecycle.identifier}} - {{lifecycle.status??"" | translate}}</p>
            </div>
            <div class="cb-entity-action-arrow">
              <cb-icon name="chevron-forward"></cb-icon>
            </div>
          </li>
        </ng-container>
      </ul>
    </div>
  </ng-template>
  
  <ng-template #ActionsListTemplate>
    <div class="cb-entity-actions-section">
      <h4>{{"ENTITY.ACTIONS" | translate}}</h4>
      <ng-container *ngIf="canEditSelectedLifecycle && !isMobile">
        <div class="cb-entity-action-buttons">
          <!-- CLOSE LIFECYCLE BUTTON -->
          <cb-button (click)="closeLifecycle()" type="accent" icon="change"
            [pTooltip]="'ENTITY.CLOSE_LIFECYCLE_TOOLTIP' | translate" tooltipPosition="top">{{"ENTITY.CLOSE_LIFECYCLE" |
            translate}}</cb-button>

          <!-- CANCEL LIFECYCLE BUTTON -->
          <cb-button *ngIf="!isSingleLifecycle" (click)="cancelLifecycle()" type="neutral" icon="close-circle"
          [pTooltip]="'ENTITY.CANCEL_LIFECYCLE_TOOLTIP' | translate" tooltipPosition="top">{{"ENTITY.CANCEL_LIFECYCLE" |
          translate}}</cb-button>
        </div>
        <div class="cb-entity-action-buttons">
          <!-- NEW ACTION BUTTON -->
          <cb-button (click)="newAction()" type="primary" icon="add-circle">{{"ENTITY.NEW_ACTION" | translate}}</cb-button>
        </div>
      </ng-container>
      <ng-container *ngIf="actions.length > 0;else NoActionTemplate">
        <ul class="cb-selection-list">
          <ng-container *ngFor="let action of actions; let idx=index">
            <li *ngVar="selectedActionIndex==idx as isSelected" [class.selected]="isSelected" (click)="selectAction(idx)">
              <div class="cb-selection-list-title">
                <p>{{action.name | actionsName}}</p>
              </div>
              <ng-container *ngIf="action.period">
                <span>{{action.period[0] | date}}
                  <ng-container *ngIf="action.period[1]">
                    -
                    {{action.period[1]?(action.period[1] |
                    date):"-"}}
                  </ng-container>
                </span>
              </ng-container>
              <div class="cb-entity-action-arrow">
                <cb-icon name="chevron-forward"></cb-icon>
              </div>
            </li>
          </ng-container>
        </ul>
      </ng-container>
      <ng-template #NoActionTemplate>
        <p class="cb-empty-message">{{"ENTITY.NO_ACTION" | translate}}</p>
      </ng-template>
    </div>
  </ng-template>
  
  <ng-template #ActionFormTemplate>
    <div class="cb-entity-action-form-section">
      <ng-container *ngIf="selectedActionIndex!=undefined; else SelectActionTemplate">
        <div class="cb-entity-action-form">
          <div class="cb-entity-action-form-header">
            <div class="cb-entity-action-form-title">
              <span>{{lifecycle?.identifier ?? ""}}</span>
              <h6>{{action?.name ?? ""}}</h6>
            </div>
            <cb-button-loading type="primary" (submit)="saveActionForm($event)" [disabled]="!canSubmitActionForm"
              [resetAfterSubmit]="true">{{"COMMON.SAVE"
              | translate}}</cb-button-loading>
          </div>
          <action-form-widget #fieldsComponent [fields]="selectedActionFields"
            [disabled]="!canSubmitActionForm"></action-form-widget>
        </div>
      </ng-container>
      <ng-template #SelectActionTemplate>
        <p class="cb-empty-message">{{"ENTITY.NO_ACTION_SELECTED" | translate}}</p>
      </ng-template>
    </div>
  </ng-template>