<cb-dialog [width]="'550px'">
    <ng-container Title>
        <div class="cb-dialog-entity-title">
            <h4>{{"ENTITY.EDIT_PROPERTIES" | translate}}</h4>
            <div class="cb-dialog-info">
                <p>{{ selectedPhase.name | translate }}</p>
            </div>
        </div>
    </ng-container>

    <ng-container Content>
        <ng-container *ngIf="!isLoading">
            <app-fields-customization-widget [inputFields]="fields"></app-fields-customization-widget>
        </ng-container>
    </ng-container>

    <ng-container RightButtons>
        <cb-button-loading (submit)="submit($event)" type="success">{{"COMMON.SAVE" | translate}}</cb-button-loading>
    </ng-container>
</cb-dialog>