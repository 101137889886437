import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CbSidebarComponent } from './cb-sidebar/cb-sidebar.component';
import { CbIconModule } from '../cb-icon/cb-icon.module';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { CbUtilityWidgetsModule } from '../cb-utility-widgets/cb-utility-widgets.module';
import { ClickOutsideDirective } from './click-outside.directive';


@NgModule({
  declarations: [
    CbSidebarComponent,
    ClickOutsideDirective
  ],
  imports: [
    CommonModule,
    CbIconModule,
    TranslateModule,
    RouterModule,
    CbUtilityWidgetsModule,
  ],
  exports: [
    CbSidebarComponent
  ]
})
export class CbSidebarModule { }
