import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataManager } from 'src/app/classes/dataManager';
import { CbLoadingButtonEvent } from 'src/app/widgets/cb-form-widgets/cb-button-loading/cb-button-loading.component';
import { PHASES_LIST, PhaseType } from '../../phases.module';
import { CbCustomField, CbCustomFieldType, FieldsCustomizationWidgetComponent } from '../../widgets/fields-customization-widget/fields-customization-widget.component';

@Component({
  selector: 'app-edit-entity-properties-dialog',
  templateUrl: './edit-entity-properties-dialog.component.html',
  styleUrls: ['./edit-entity-properties-dialog.component.scss']
})
export class EditEntityPropertiesDialogComponent implements OnInit {

  public isLoading: boolean = false;
  public fields: CbCustomField[] = [];
  @ViewChild(FieldsCustomizationWidgetComponent) fieldsCustomizationWidget!: FieldsCustomizationWidgetComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { entity: any, type: PhaseType },
    private readonly dialogRef: MatDialogRef<EditEntityPropertiesDialogComponent>
  ) { }

  ngOnInit(): void {
    this.loadFields();
  }

  private async loadFields(): Promise<void> {
    this.isLoading = true;
    try {
      this.fields = await DataManager.getInstance().fromPhasePropertiesToFields(this.data.type);
      this.isLoading = false;
    } catch (error) {
      this.isLoading = false;
    }
  }

  public get selectedPhase() {
    return PHASES_LIST.find(phase => phase.type === this.data.type) ?? PHASES_LIST[0];
  }

  public async submit(callbacks: CbLoadingButtonEvent): Promise<void> {
    const fields: CbCustomField[] = this.fieldsCustomizationWidget.fields;
    const updatedProperties = DataManager.getInstance().fromFieldsToPhaseProperties(fields);
    try {
      await DataManager.getInstance().updatePhaseProperties(this.data.type, updatedProperties, callbacks);
      callbacks.success.next();
      setTimeout(() => {
        this.dialogRef.close(true);
      }, 200);
    } catch (error) {
      callbacks.error.next();
    }
  }
}
