<div class="cb-settings-popover">
    <ul>
        <ng-container *ngFor="let option of settingsList">
            <li [ngClass]="'option-'+option.type" (click)="$event.preventDefault();settingClicked.emit(option.name)">
                <ng-container *ngIf="option.icon">
                    <cb-icon [name]="option.icon"></cb-icon>
                </ng-container>
                <p>{{option.label | translate}}</p>
            </li>
        </ng-container>
    </ul>
</div>